import { useToast } from "@slid/slid-ips";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  setCurrentSTTData,
  setIsGettingSTTMediaPermission,
  setIsManualAddingMode,
  setIsSTTSessionStarted,
  setIsSTTSocketConnected,
  setLastSTTDataAsTranscribed,
  setLeftSTTDataAsTranscribedWhenDeactivated,
  setSTTError,
  setSmartLiveTextLanguage,
} from "redux/actions/sttActions";
import { setIsExtensionMediaPermitted, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import store from "redux/store";
import { trackEvent } from "utils/eventTracking";
import {
  setIsExtensionClickedListener,
  setSTTSocketConnectionStatusListener,
  setSTTDataResponseListener,
  setSTTErrorListener,
  setWhisperResponseListener,
  setWhisperFailedListener,
} from "utils/extensionInterface/setListenerFromExtension";

export const useInitializeSLT = () => {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { t } = useTranslation(["VideoNote"]);
  const { isSTTActive, smartLiveTextLanguage, isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = useAppSelector((state) => state.vdocs);
  const userDefaultLanguage = window.localStorage.getItem("slid-user-stt-default-lang");
  const [localSTTData, setLocalSTTData] = useState<{
    text: string;
    isFinal: boolean;
    isTyping?: boolean;
    isWhisperTranscribed?: boolean;
  } | null>(null);

  useEffect(() => {
    dispatch(setIsSTTSessionStarted());
  }, [smartLiveTextLanguage, isVideoPlaying, isExtensionMediaPermitted, isSTTToggledOn, currentVideo]);

  useEffect(() => {
    setSLTDefaultOptions();
  }, []);

  useEffect(() => {
    if (!isSTTActive) dispatch(setLeftSTTDataAsTranscribedWhenDeactivated());
  }, [isSTTActive]);

  // set listeners for extension events
  useEffect(() => {
    setIsExtensionClickedListener({
      responseHandler: async () => {
        if (store.getState().sttReducer.isGettingSTTMediaPermission && !store.getState().vdocs.isExtensionMediaPermitted) {
          dispatch(setIsExtensionMediaPermitted(true));
          dispatch(setShowExtensionMediaPermissionModal(false));
          dispatch(setIsGettingSTTMediaPermission(false));
          showToast(t("ToastForPermissionGranted", { ns: "VideoNote" }));
          trackEvent({
            eventType: "Success SMART LIVETEXT PERMISSION in video note page",
          });
        }
      },
    });
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        dispatch(setIsSTTSocketConnected(isSTTSocketConnected));
      },
    });
    setSTTDataResponseListener({
      responseHandler: (receivedData: any) => {
        setLocalSTTData(receivedData);
      },
    });
    setWhisperResponseListener({
      responseHandler: (receivedData: any) => {
        dispatch(
          setCurrentSTTData({
            text: receivedData.result,
            isFinal: true,
            isWhisperTranscribed: true,
          })
        );
      },
    });
    setWhisperFailedListener({
      responseHandler: (receivedData: any) => {
        dispatch(setLastSTTDataAsTranscribed());
      },
    });
    setSTTErrorListener({
      responseHandler: (receivedData: any) => {
        dispatch(setSTTError(receivedData.error));
      },
    });
  }, []);

  useEffect(() => {
    dispatch(setCurrentSTTData(localSTTData));
  }, [localSTTData]);

  const setSLTLanguageFromUserDefaultLanguage = () => {
    trackEvent({
      eventType: "Check LIVETEXT DEFAULT LANGUAGE in video note page",
      eventProperties: { is_default_language_set: !!userDefaultLanguage, default_language: userDefaultLanguage },
    });
    if (userDefaultLanguage) {
      dispatch(setSmartLiveTextLanguage(userDefaultLanguage));
    }
  };

  const setSLTAddingModeFromUserDefaultAddingMode = () => {
    const isManualAddingMode = window.localStorage.getItem("slid-stt-manual-adding-mode");
    if (isManualAddingMode === "true") {
      dispatch(setIsManualAddingMode(true));
    } else {
      dispatch(setIsManualAddingMode(false));
    }
  };

  const setSLTDefaultOptions = () => {
    setSLTLanguageFromUserDefaultLanguage();
    setSLTAddingModeFromUserDefaultAddingMode();
  };

  return {};
};
