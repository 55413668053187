import { useQuery } from "@tanstack/react-query";
import { imageSearch } from "query-api/search";
import store from "redux/store";
import { MembershipType } from "utils/privilegeManager";

interface SearchParams {
  keyword: string;
}

export const useImageSearch = (props: SearchParams) => {
  const { keyword } = props;
  const isUserHasPrivilege = [MembershipType.premium, MembershipType.pro, MembershipType.trial].includes(store.getState().slidGlobal.userData?.payment);

  const { data, isError, isLoading } = useQuery({
    queryKey: ["imageSearch", keyword],
    queryFn: async () => {
      if (!isUserHasPrivilege)
        return {
          documents: [],
          number_of_results: 0,
        };
      const { data } = await imageSearch({
        keyword,
      });

      return data;
    },
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError };
};
