import { createDocument } from "redux/actions/vdocsActions";
import store from "redux/store";

export const isPreviousBlockEmpty = (previousBlockIndex: number) => {
  const { blocks } = store.getState().vdocs.editorInstance;
  const { getBlockByIndex } = blocks;
  const previousBlock = getBlockByIndex(previousBlockIndex);
  return typeof previousBlock !== "undefined" && previousBlock !== "image" && previousBlock.isEmpty;
};

export const getCurrentDocumentKey = async () => {
  const currentDocument = store.getState().vdocs.currentDocument;

  if (currentDocument && currentDocument["document_key"] !== "new") {
    return currentDocument["document_key"];
  }
  const createdDocument = await store.dispatch(createDocument({ origin: "vdocs" }));
  if (createdDocument.error_message) return;
  return createdDocument["document_key"];
};
