import { sendIsSTTSessionStartedToParentWindow, sendStartSTTRequestToParentWindow, sendStopSTTRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import {
  //for transcription
  SET_IS_STT_ACTIVE,
  ADD_STT_DATA,
  SET_STT_ERROR,
  SET_SHOW_SMART_LIVE_TEXT_VIEW,
  SET_SMART_LIVE_TEXT_LANGUAGE,
  SET_IS_STT_TOGGLED_ON,
  SET_IS_STT_SOCKET_CONNECTED,
  SET_IS_GETTING_STT_MEDIA_PERMISSION,
  SET_IS_STT_SESSION_STARTED,
  SET_IS_MANUAL_ADDING_MODE,
  SET_IS_STT_MORE_BUTTON_CLICKED,
  RESET_STT_DATA,
  SET_IS_STT_V2_SUPPORTED,
  SET_PROCESSED_STT_DATA,
  SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW,
  SET_IS_LANGUAGE_SELECTED_FROM_HEADER,
  SET_IS_SLT_STARTED_BY_USER,
  SET_CURRENT_STT_DATA,
  SET_STT_ACTIVE_NOTE_BLOCK,
} from "./actionTypes";
import store from "../store";
import { sendMessageToPrimary } from "../../utils/utils";
import { trackEvent } from "utils/eventTracking";
import { Dispatch } from "@reduxjs/toolkit";

export const setProcessedSTTData = (data) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PROCESSED_STT_DATA,
    payload: data,
  });
};

export const resetSTTData = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_STT_DATA,
    payload: true,
  });
};

export const addSTTData = (data) => (dispatch: Dispatch) => {
  dispatch({
    type: ADD_STT_DATA,
    payload: data,
  });
};

export const setCurrentSTTData = (data) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CURRENT_STT_DATA,
    payload: data,
  });
};

export const setStartSTT = (language) => (dispatch: Dispatch) => {
  const transcriptLanguage = getTranscriptLanguageCode(language);
  sendStartSTTRequestToParentWindow(transcriptLanguage);
  dispatch({
    type: SET_IS_STT_ACTIVE,
    payload: true,
  });
};

export const setStopSTT = () => (dispatch: Dispatch) => {
  sendStopSTTRequestToParentWindow();
  dispatch({
    type: SET_IS_STT_ACTIVE,
    payload: false,
  });
};

export const setStartDesktopSTT = (language) => (dispatch: Dispatch) => {
  const iframePort = store.getState().vdocs.iframePort;
  const transcriptLanguage = getTranscriptLanguageCode(language);

  sendMessageToPrimary({
    message: {
      type: "IFRAME_TO_PRIMARY_startSLT",
      payload: transcriptLanguage,
    },
    port: iframePort,
  });
  dispatch({
    type: SET_IS_STT_ACTIVE,
    payload: true,
  });
};

export const setStopDesktopSTT = () => (dispatch: Dispatch) => {
  const iframePort = store.getState().vdocs.iframePort;

  sendMessageToPrimary({
    message: {
      type: "IFRAME_TO_PRIMARY_stopSLT",
    },
    port: iframePort,
  });
  dispatch({
    type: SET_IS_STT_ACTIVE,
    payload: false,
  });
};

export const setSTTError = (error) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_STT_ERROR,
    payload: { error },
  });
};

export const setActiveSTTNoteBlock = (newBlock) => (dispatch) => {
  dispatch({
    type: SET_STT_ACTIVE_NOTE_BLOCK,
    payload: newBlock,
  });
};

export const setIsLanguageSelectedFromHeader = (isLanguageSelectedFromHeader) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_LANGUAGE_SELECTED_FROM_HEADER,
    payload: isLanguageSelectedFromHeader,
  });
};

export const setShowSmartLiveTextView = (showSmartLiveTextView) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_SMART_LIVE_TEXT_VIEW,
    payload: showSmartLiveTextView,
  });
};

export const setSmartLiveTextLanguage = (smartLiveTextLanguage) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SMART_LIVE_TEXT_LANGUAGE,
    payload: smartLiveTextLanguage,
  });
};

export const setIsSTTToggledOn = (isSTTToggledOn) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_STT_TOGGLED_ON,
    payload: isSTTToggledOn,
  });
};

export const setIsSTTSocketConnected = (isSTTSocketConnected) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_STT_SOCKET_CONNECTED,
    payload: isSTTSocketConnected,
  });
};

export const setIsGettingSTTMediaPermission = (isGettingSTTMediaPermission) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_GETTING_STT_MEDIA_PERMISSION,
    payload: isGettingSTTMediaPermission,
  });
};

export const setIsSTTSessionStarted = () => (dispatch: Dispatch) => {
  const { smartLiveTextLanguage, isSTTToggledOn, isSTTActive, isSTTStartedByUser } = store.getState().sttReducer;
  const { applicationType } = store.getState().slidGlobal;
  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = store.getState().vdocs;
  if (applicationType === "desktop") {
    if (!smartLiveTextLanguage && isSTTToggledOn) {
      dispatch<any>(setShowSmartLiveTextView(true));
    } else if (smartLiveTextLanguage && isSTTToggledOn) {
      if (!isSTTActive) dispatch<any>(setStartDesktopSTT(smartLiveTextLanguage));
      dispatch({
        type: SET_IS_STT_SESSION_STARTED,
        payload: true,
      });
      if (!isSTTStartedByUser) {
        trackEvent({ eventType: "Start SMART LIVETEXT in video note page" });
        setIsSTTStartedByUser(true);
      }
    } else {
      dispatch<any>(setStopDesktopSTT());
      dispatch<any>(setStopSTT());

      if (!isSTTToggledOn) {
        // Set isSTTStartedByUser to false when STT is toggled off by user, for tracking if user wants to start STT again
        setIsSTTStartedByUser(false);
      }
      dispatch({
        type: SET_IS_STT_SESSION_STARTED,
        payload: false,
      });
    }
    return;
  }

  const shouldStartSLT = smartLiveTextLanguage && isExtensionMediaPermitted && isSTTToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);

  if (shouldStartSLT) {
    if (!isSTTActive) {
      dispatch<any>(setStartSTT(smartLiveTextLanguage));
    }
    sendIsSTTSessionStartedToParentWindow(true);
    dispatch({
      type: SET_IS_STT_SESSION_STARTED,
      payload: true,
    });
    if (!isSTTStartedByUser) {
      trackEvent({ eventType: "Start SMART LIVETEXT in video note page" });
      dispatch<any>(setIsSTTStartedByUser(true));
    }
  } else if (isSTTActive) {
    dispatch<any>(setStopSTT());
    sendIsSTTSessionStartedToParentWindow(false);
    dispatch({
      type: SET_IS_STT_SESSION_STARTED,
      payload: false,
    });
  }
};

export const setIsManualAddingMode = (isManualAddingMode) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_MANUAL_ADDING_MODE,
    payload: isManualAddingMode,
  });
};

export const setLeftSTTDataAsTranscribedWhenDeactivated = () => (dispatch: Dispatch) => {
  dispatch<any>(addSTTData(updateLeftSTTDataAsTranscribed(store.getState().sttReducer.processedSTTData)));
  dispatch<any>(setProcessedSTTData([]));
  dispatch<any>(setCurrentSTTData(null));
};
export const setLastSTTDataAsTranscribed = () => (dispatch: Dispatch) => {
  dispatch<any>(addSTTData(updateLastSTTDataAsTranscribed(store.getState().sttReducer.processedSTTData)));
  dispatch<any>(setCurrentSTTData(null));
  dispatch<any>(setProcessedSTTData([]));
};

export const setIsSTTv2Supported = (isSTTv2Supported) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_STT_V2_SUPPORTED,
    payload: isSTTv2Supported,
  });
};

export const setIsSTTMoreButtonClicked = (isSTTMoreButtonClicked) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_STT_MORE_BUTTON_CLICKED,
    payload: isSTTMoreButtonClicked,
  });
};

export const setShowPermissionGuideInTextView = (showPermissionGuideInTextView) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW,
    payload: showPermissionGuideInTextView,
  });
};

export const setIsSTTStartedByUser = (isSTTStartedByUser) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_SLT_STARTED_BY_USER,
    payload: isSTTStartedByUser,
  });
};

const getTranscriptLanguageCode = (language) => {
  const languageCodes = {
    English: "en-US",
    한국어: "ko-KR",
    中文: "cmn-Hans-CN",
    हिन्दी: "hi-IN",
    Español: "es-ES",
    Français: "fr-FR",
    日本語: "ja-JP",
    Português: "pt-PT",
    Deutsch: "de-DE",
    Dutch: "nl-NL",
  };
  return languageCodes[language] || "en-US";
};

function updateLeftSTTDataAsTranscribed(sttData: any) {
  if (sttData?.length === 0) return sttData;
  const newState = sttData?.map((data) => {
    if (!data?.isWhisperTranscribed) {
      data.isWhisperTranscribed = true; // if isSTTActive, clearly make it able to insert
      data.isFinal = true; //
    }
    return data;
  });

  return newState;
}
function updateLastSTTDataAsTranscribed(sttData) {
  if (sttData?.length === 0) return sttData;
  let lastWhisperTranscribedIdx = -1;
  const newState = [...sttData];
  for (let i = newState.length - 1; i >= 0; i--) {
    if (newState[i].isWhisperTranscribed) {
      lastWhisperTranscribedIdx = i;
      break;
    }
  }
  if (newState[lastWhisperTranscribedIdx + 1]) {
    newState[lastWhisperTranscribedIdx + 1].isWhisperTranscribed = true;
    newState[lastWhisperTranscribedIdx + 1].isFinal = true;
  }
  return newState;
}
