export const SuccessEventType = {
  //FREE TRIAL
  START_FREE_TRIAL: "Start free trial",

  //24H DAY-PASS
  _24H_PASS_ACTIVATION: "Success 24H Pass activation",

  //ELSE
  SUBSCRIPTION_CANCELLATION: "Subscription-cancellation completed", // should keep legacy
  CLIP_RECORDING_START: "Success CLIP RECORDING START",
  MEMBERSHIP_PAYMENT: "Buy membership",

  // Options in video note
  DELETE_CAPTURED_IMAGE_FROM_ALBUM: "Success DELETE CAPTURED IMAGE FROM ALBUM",
};
