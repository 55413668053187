//auth 2.0

import { configureStore, Reducer, AnyAction, getDefaultMiddleware } from "@reduxjs/toolkit";
import slidGlobal from "./reducers/slidGlobal";
import vdocs from "./reducers/vdocs";
import pricing from "./reducers/pricing";
import demo from "./reducers/demo";
import sttReducer from "./reducers/sttReducer";
import search from "./reducers/search";
import editor from "./reducers/editor";
import { IPSReducer } from "@slid/slid-ips";
import aiSliddySliceReducer from "./modules/aiSliddySlice";
import myNotesSliceReducer from "./modules/myNotesSlice";

interface IPSReducerType {
  toast: Reducer<any, AnyAction>;
  modal: Reducer<any, AnyAction>;
  alert: Reducer<any, AnyAction>;
}

const typedIPSReducer: IPSReducerType = IPSReducer;

const store = configureStore({
  reducer: {
    slidGlobal,
    vdocs,
    pricing,
    demo,
    sttReducer,
    search,
    myNotes: myNotesSliceReducer,
    aiSliddy: aiSliddySliceReducer,
    editor,
    ...typedIPSReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
