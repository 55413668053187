import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllStudyChallenges, createStudyChallenge, updateStudyChallenge, deleteStudyChallenge, StudyChallengeRequest } from "query-api/study-challenge-admin";

export const useStudyChallenges = () => {
  const queryClient = useQueryClient();

  const { data: studyChallenges, isLoading } = useQuery({
    queryKey: ["studyChallenges"],
    queryFn: getAllStudyChallenges,
    select: (response) => {
      const { data } = response;

      return data;
    },
  });

  const { mutateAsync: createStudyChallengeMutation } = useMutation({
    mutationFn: createStudyChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries(["studyChallenges"]);
    },
  });

  const { mutateAsync: updateStudyChallengeMutation } = useMutation({
    mutationFn: ({ studyChallengeId, data }: { studyChallengeId: number; data: StudyChallengeRequest }) => updateStudyChallenge(studyChallengeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["studyChallenges"]);
    },
  });

  const { mutateAsync: deleteStudyChallengeMutation } = useMutation({
    mutationFn: deleteStudyChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries(["studyChallenges"]);
    },
  });

  return {
    studyChallenges,
    isLoading,
    createStudyChallenge: createStudyChallengeMutation,
    updateStudyChallenge: updateStudyChallengeMutation,
    deleteStudyChallenge: deleteStudyChallengeMutation,
  };
};
