//auth 2.0
import { cloneDeep, strictParseInt, convertToArrow, getTextDataToMerge } from "utils/utils";
import "./index.css";

/**
 * @typedef {object} ParagraphConfig
 * @property {string} placeholder - placeholder for the empty paragraph
 * @property {boolean} preserveBlank - Whether or not to keep blank paragraphs when saving editor data
 */

/**
 * @typedef {Object} ParagraphData
 * @description Tool's input and output data format
 * @property {String} text — Paragraph's content. Can include HTML tags: <a><b><i>
 */
class Paragraph {
  /**
   * Default placeholder for Paragraph Tool
   *
   * @return {string}
   * @constructor
   */
  static get DEFAULT_PLACEHOLDER() {
    return "";
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - constructor params
   * @param {ParagraphData} params.data - previously saved data
   * @param {ParagraphConfig} params.config - user config for Tool
   * @param {object} params.api - editor.js api
   * @param {boolean} readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;
    this.state = {
      isBackspacePressed: false,
    };
    this._CSS = {
      block: this.api.styles.block,
      wrapper: "ce-paragraph",
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
      this.onInput = this.onInput.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
    }

    /**
     * Placeholder for paragraph if it is first Block
     * @type {string}
     */
    this._placeholder = config.placeholder ? config.placeholder : Paragraph.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = this.drawView();
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;
  }

  /**
   * Check if text content is empty and set empty string to inner html.
   * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
   *
   * @param {KeyboardEvent} e - key up event
   */
  onKeyUp(e) {
    if (e.code === "Backspace") {
      this.state.isBackspacePressed = false;
    }
    if (e.code !== "Backspace" && e.code !== "Delete") {
      return;
    }

    const { textContent } = this._element;

    if (textContent === "") {
      this._element.innerHTML = "";
    }
  }

  onKeyDown(e) {
    convertToArrow(e);
    switch (e.key) {
      // When text from head to caret is space or tab, EditorJS merges this block with previous block.
      // So stop event bubbling by event.stopPropagation(); when it's space or tab
      case "Tab":
        if (e.shiftKey) {
          this.onShiftTabPressed(e, this._element);
        } else {
          this.onTabPressed(e);
        }
        break;
      case "Backspace":
        this.onBackspacePressed(e, this._element);
        break;
      default:
        return;
    }
  }

  // auto format by markdown syntax
  onInput(e) {
    if (this.state.isBackspacePressed) return;
    const textContent = this._element.innerHTML;
    const markdownRules = this.config.MarkdownRules;
    // eslint-disable-next-line no-unused-expressions
    markdownRules.forEach(({ syntax, block: { type, config, data } }) => {
      this.onCreateByMarkdownSyntax({ api: this.api, type, config, data, textContent, syntax, focusSelf: config.focusSelf });
    });
  }

  onCreateByMarkdownSyntax({ api, textContent, type, data, config, syntax, focusSelf }) {
    const currentBlockIndex = api.blocks.getCurrentBlockIndex();
    const replaceCurrentBlockToNewBlock = (data) => {
      // TODO : It makes caret moving weird.
      api.blocks.delete(currentBlockIndex);
      api.blocks.insert(type, data, config, currentBlockIndex, true);
    };

    const focusNextBlock = () => {
      api.blocks.insert();
      api.caret.setToBlock(currentBlockIndex + 1);
    };
    const createBlockWithoutTextContent = () => {
      replaceCurrentBlockToNewBlock(cloneDeep(data));
      if (focusSelf) {
        api.caret.setToBlock(currentBlockIndex);
      } else {
        focusNextBlock();
      }
    };

    const createBlockWithTextContent = (text) => {
      const newData = cloneDeep(data);
      if (!newData) return;
      switch (type) {
        case "unorderedList":
          newData.items = [{ text }];
          break;
        case "orderedList":
          newData.items = [{ text }];
          break;
        case "blockChecklist":
          newData.items = [{ text, checked: false }];
          break;
        case "codeMirrorTool":
          newData.code = text;
          break;
        default:
          newData.text = text;
      }
      replaceCurrentBlockToNewBlock(newData);
      api.caret.setToBlock(currentBlockIndex);
    };

    const checkIfOrderedListSyntaxAndMake = () => {
      const match = textContent.match(/^([0-9]+)\. (.*)/);
      if (match && type === "orderedList") {
        const number = match[1];
        const existTextContent = match[2];
        data.numberingLevels[0][0] = number;
        if (existTextContent && existTextContent.length >= 0) {
          createBlockWithTextContent(existTextContent);
        } else createBlockWithoutTextContent();
        return;
      }
    };

    checkIfOrderedListSyntaxAndMake();

    if (textContent === syntax) {
      createBlockWithoutTextContent();
      return;
    }

    if (textContent.substring(0, syntax.length) === syntax) {
      createBlockWithTextContent(textContent.substring(syntax.length));
      return;
    }
  }
  /**
   * @return void
   * @param {KeyboardEvent} e - on Tab Key Pressed
   */
  onTabPressed(e) {
    const { textContent } = this._element;
    const isBlockEmpty = textContent === "";

    if (!isBlockEmpty) {
      /**
       * Prevent editor.js behaviour
       */
      e.stopPropagation();
      /**
       * Prevent browser tab behaviour
       */
      e.preventDefault();
      /**
       * Insert `Tab`
       */
      document.execCommand("insertHTML", false, "&#009");
    }
  }

  /**
   * @return void
   * @param {KeyboardEvent} e - on Backspace Key Pressed
   */
  onBackspacePressed(e, div) {
    this.state.isBackspacePressed = true;
    const isPrevTextSpaceOrTab =
      // space
      this.getTextFromHeadToCaret(div).includes(" ") ||
      // tab
      this.getTextFromHeadToCaret(div).includes("	");

    if (isPrevTextSpaceOrTab) {
      e.stopPropagation();
    }
  }

  /**
   * @return void
   * @param {KeyboardEvent} e - on Tab Key Pressed
   */
  onShiftTabPressed(e, div) {
    /**
     * Prevent editor.js behavior
     */
    e.stopPropagation();

    /**
     * Prevent browser tab behavior
     */
    e.preventDefault();

    /**
     * Do unshift for remove Tab
     */
    this.unshift(div);
  }

  unshift(div) {
    const getCaretCharOffset = (element) => {
      var caretOffset = 0;

      if (window.getSelection) {
        var range = window.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      } else if (document.selection && document.selection.type != "Control") {
        var textRange = document.selection.createRange();
        var preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }

      return caretOffset;
    };

    if (!this.data) return;
    // Get the block's content and Check is Tab or not.
    const firstSpaceOfParagraph = this.data["text"][0];
    const isFirstSpaceTab = /\t/gi.test(firstSpaceOfParagraph);
    const currentData = this.data["text"];
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    const currentCaretOffset = getCaretCharOffset(div);
    const inFrontOfCurrentCaret = this.data["text"][currentCaretOffset - 1];
    const isInFrontOfCurrentCaretTab = /\t/gi.test(inFrontOfCurrentCaret);

    /**
     * Delete if the first space of paragraph is Tab.
     * else, check in front of current caret position is Tab and Delete.
     */
    if (isFirstSpaceTab) {
      // Delete Tab and replace
      const slicedData = currentData.substring(1, currentData.length);
      this.api.blocks.delete(currentBlockIndex);
      this.api.blocks.insert(
        "paragraph",
        {
          text: slicedData,
        },
        {},
        undefined,
        false
      );
      this.api.caret.setToBlock(currentBlockIndex, "default", currentCaretOffset - 1);
    } else if (isInFrontOfCurrentCaretTab) {
      const preSlicedData = currentData.substring(0, currentCaretOffset - 1);
      const postSlicedData = currentData.substring(currentCaretOffset, currentData.length);
      const slicedData = preSlicedData.concat(postSlicedData);
      this.api.blocks.delete(currentBlockIndex);
      this.api.blocks.insert(
        "paragraph",
        {
          text: slicedData,
        },
        {},
        undefined,
        false
      );
      this.api.caret.setToBlock(currentBlockIndex, "default", currentCaretOffset - 1);
    }
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    let div = document.createElement("DIV");

    div.classList.add(this._CSS.wrapper, this._CSS.block);
    div.contentEditable = false;
    div.spellcheck = false;
    div.dataset.placeholder = this.api.i18n.t(this._placeholder);
    div.setAttribute("slid-cy", "editor-paragraph");

    if (!this.readOnly) {
      div.contentEditable = true;
      div.addEventListener("keyup", this.onKeyUp);
      div.addEventListener("input", this.onInput, false);
      div.addEventListener("keydown", this.onKeyDown);
    }

    return div;
  }

  getTextFromHeadToCaret(element) {
    var caretOffset = 0;
    if (typeof window.getSelection != "undefined") {
      var range = window.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    } else if (typeof document.selection != "undefined" && document.selection.type != "Control") {
      var textRange = document.selection.createRange();
      var preCaretTextRange = document.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    var divStr = element.innerText;
    return divStr.substring(0, caretOffset);
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement}
   */
  render() {
    return this._element;
  }

  /**
   * Method that specified how to merge two Text blocks.
   * Called by Editor.js by backspace at the beginning of the Block
   * @param {ParagraphData} data
   * @public
   */
  merge(data) {
    let newData = {
      text: this.data.text + getTextDataToMerge(data),
    };

    this.data = newData;
  }

  /**
   * Validate Paragraph block data:
   * - check for emptiness
   *
   * @param {ParagraphData} savedData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(savedData) {
    if (savedData.text.trim() === "" && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  /**
   * Extract Tool's data from the view
   * @param {HTMLDivElement} - Paragraph tools rendered view
   * @returns {ParagraphData} - saved data
   * @public
   */
  save() {
    return {
      text: this._element?.innerHTML,
    };
  }

  moved(event) {
    if (!document.getElementById("editor-container")) return;
    const currentScrollTop = document.getElementById("editor-container").scrollTop;
    const changedHeight = this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex()).holder.offsetHeight;
    if (event.detail.fromIndex < event.detail.toIndex) {
      document.getElementById("editor-container").scrollTop = currentScrollTop + changedHeight;
    } else {
      document.getElementById("editor-container").scrollTop = currentScrollTop - changedHeight;
    }
  }

  /**
   * On paste callback fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted data
   */
  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML,
    };

    this.data = data;
  }

  /**
   * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
   */
  static get conversionConfig() {
    return {
      export: "text", // to convert Paragraph to other block, use 'text' property of saved data
      import: "text", // to covert other block's exported string to Paragraph, fill 'text' property of tool data
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }

  /**
   * Returns true to notify the core that read-only mode is supported
   *
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get current Tools`s data
   * @returns {ParagraphData} Current data
   * @private
   */
  get data() {
    let text = this._element.innerHTML;

    this._data.text = text;

    return this._data;
  }

  /**
   * Store data in plugin:
   * - at the this._data property
   * - at the HTML
   *
   * @param {ParagraphData} data — data to set
   * @private
   */
  set data(data) {
    this._data = data || {};

    this._element.innerHTML = this._data.text || "";
  }

  /**
   * Used by Editor paste handling API.
   * Provides configuration to handle P tags.
   *
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: ["P", "SPAN"],
    };
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: `
      <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.937988C0.5 0.661846 0.723858 0.437988 1 0.437988H13C13.2761 0.437988 13.5 0.661846 13.5 0.937988V3.18799C13.5 3.46413 13.2761 3.68799 13 3.68799C12.7239 3.68799 12.5 3.46413 12.5 3.18799V1.43799H7.5V12.5H9.57143C9.80812 12.5 10 12.7239 10 13C10 13.2761 9.80812 13.5 9.57143 13.5H7H4.42857C4.19188 13.5 4 13.2761 4 13C4 12.7239 4.19188 12.5 4.42857 12.5H6.5V1.43799H1.5V3.18799C1.5 3.46413 1.27614 3.68799 1 3.68799C0.723858 3.68799 0.5 3.46413 0.5 3.18799V0.937988Z" fill="currentColor" />
</svg>
      `,
      title: "Paragraph",
    };
  }
  static convertToPlainHTML(data) {
    return `<p>${data.text}</p>`;
  }
}

export default Paragraph;
