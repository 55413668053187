// auth 2.0
import { deviceType } from "react-device-detect";
import { isMobileApp, isTabletApp, openExtensionWebStoreInNewTab, sendMessageToPrimary, redirectToMyNotes } from "utils/utils";
import { CustomModalProps, EmojiModalProps, ImageModalProps, ModalProps, ModalTypes } from "@slid/slid-ips";
import { activateDayPass } from "redux/actions/pricingActions";
import { History } from "history";
import i18n from "config/i18n/i18n";
import store from "redux/store";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { alertProcessing } from "components/alerts";
import Swal from "sweetalert2";
import env from "config/env";
import { deleteAccount } from "redux/actions/slidGlobalActions";
import { signUserOut } from "utils/auth/cognitoAuthToolkit";
import * as Sentry from "@sentry/browser";
import { MembershipType } from "../privilegeManager";
export interface ShowModalProps {
  showModal: Function;
  closeModal: Function;
  history?: History;
  eventOrigin?: string;
  dispatch?: any;
  currentDocumentKey?: any;
  customComponentContent?: any;
  parameter?: any;
  closableDim?: boolean;
}

const t = i18n.t.bind(i18n);

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
const shouldShowMobileSizeModal = deviceType === "mobile" || (isMobileApp() && !isTabletApp()); // mobile app's deviceType is not considered as "mobile".

export const showDownloadExtensionModal = async ({ showModal, closeModal }: ShowModalProps) => {
  trackEvent({
    eventType: "Show download extension modal",
  });

  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    title: t("DownloadExtensionModalTitle", { ns: "Modal" }),
    text: t("DownloadExtensionModalBody", { ns: "Modal" }),
    primaryButton: {
      text: t("DownloadExtensionModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        openExtensionWebStoreInNewTab();
        closeModal();
      },
    },
    secondaryButton: {
      text: t("DownloadExtensionModalSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};
export const showWantToContinueCapturingModal = async ({ showModal, closeModal }: ShowModalProps) => {
  trackEvent({
    eventType: "Show want to continue capturing modal",
  });

  const lang = await store.getState().slidGlobal.lang;

  const modalProps: ImageModalProps = {
    type: ModalTypes.imageModal,
    title: t("WantToContinueCapturingModalTitle", { ns: "Modal" }),
    text: t("WantToContinueCapturingModalBody", { ns: "Modal" }),
    primaryButton: {
      text: t("WantToContinueCapturingModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
    image: {
      src: `https://slid-public-assets.s3.us-west-1.amazonaws.com/images/slid_web_capture_demo_${lang.toUpperCase()}.gif`,
      altText: "Want to continue capture modal video",
      width: 400,
      height: 235,
    },
  };
  showModal(modalProps);
};

export const showExportPDFModal = async ({ showModal, closeModal, currentDocumentKey }: ShowModalProps) => {
  trackEvent({
    eventType: "Show export PDF modal in extension",
  });

  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    title: t("ExportPDFinMyNoteModalTitle", { ns: "Modal" }),
    text: t("ExportPDFinMyNoteModalBody", { ns: "Modal" }),
    primaryButton: {
      text: t("ExportPDFinMyNoteModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
        window.open(`/docs/${currentDocumentKey}`);
      },
    },
    secondaryButton: {
      text: t("ExportPDFinMyNoteModalSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showNeedToSignUpForDemoModal = async ({ showModal, closeModal }: ShowModalProps, feature: "myNote" | "image") => {
  const applicationType = await store.getState().slidGlobal.applicationType;

  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("NeedToSignUpModalIcon", { ns: "Modal" }),
    title: t("NeedToSignUpTitle", { ns: "Modal" }),
    text: t(feature === "myNote" ? "NeedToSignUpForMyNote" : "NeedToSignUpForImageFeatures", { ns: "Modal" }),
    primaryButton: {
      text: t("NeedToSignUpPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
        trackEvent({
          eventType: "click SIGN-UP in demo",
          eventProperties: {
            location: "modal",
            platfrom: applicationType,
          },
        });
        window.open(`/sign-up`);
      },
    },
    secondaryButton: {
      text: t("NeedToSignUpSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showAuthRequiredModal = async ({ showModal, closeModal }: ShowModalProps, isUserExpired: boolean, openSignUp: () => void, openSignIn: () => void, startPolling: () => void) => {
  const modalProps: ImageModalProps = {
    type: ModalTypes.imageModal,
    textStyle: "Typography28",
    title: t("AuthRequiredModalTitle", { ns: "Modal" }),
    image: {
      src: "/src/design/assets/slid_welcome.png",
      altText: "Auth required",
      width: 360,
      height: 242,
    },
    primaryButton: {
      text: isUserExpired ? t("AuthRequiredModalSignInButton", { ns: "Modal" }) : t("AuthRequiredModalSignUpButton", { ns: "Modal" }),
      callBack: () => {
        isUserExpired ? openSignUp() : openSignIn();
      },
    },
    linkButton: {
      href: isUserExpired ? `${SLID_WEB_APP_URL}/sign-up?previous=instant-launch` : `${SLID_WEB_APP_URL}/sign-in?previous=instant-launch`,
      linkText: isUserExpired ? t("AuthRequiredLinkSignUpText", { ns: "Modal" }) : t("AuthRequiredLinkSignInText", { ns: "Modal" }),
      infoText: isUserExpired ? t("AuthRequiredLinkSignUpDescText", { ns: "Modal" }) : t("AuthRequiredLinkSignInDescText", { ns: "Modal" }),
      newTab: true,
      onClick: () => {
        startPolling();
        if (isUserExpired) {
          trackEvent({
            eventType: "Click sign-up in auth-required page",
          });
        } else {
          trackEvent({
            eventType: "Click sign-in in auth-required page",
          });
        }
      },
    },
    closableDim: false,
  };
  showModal(modalProps);
};

export const showConfirmAccountDeletionModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("ConfirmAccountDeletionModalIcon", { ns: "Modal" }),
    title: t("ConfirmAccountDeletionModalTitle", { ns: "Modal" }),
    text: t("ConfirmAccountDeletionModalText", { ns: "Modal" }),
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    primaryButton: {
      text: t("ConfirmAccountDeletionModalPrimaryButton", { ns: "Modal" }),
      callBack: async () => {
        closeModal();
        trackEvent({
          eventType: "Click DELETE ACCOUNT in my account",
        });
        alertProcessing({ clickableDim: false });
        await store.dispatch(deleteAccount());
        Swal.close();
        await signUserOut();
      },
    },
    secondaryButton: {
      text: t("ConfirmAccountDeletionModalSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showNotAbleOpenPricingModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    title: t("NotAbleOpenPricingModalTitle", { ns: "Modal" }),
    text: t("NotAbleOpenPricingModalText", { ns: "Modal" }),
    primaryButton: {
      text: t("NotAbleOpenPricingModalButton", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showFailToLoadWindowModal = async ({ showModal, closeModal }: ShowModalProps, openShowSelectWindowModal: any) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("FailToLoadWindowModalEmoji", { ns: "Desktop" }),
    title: t("FailToLoadWindowModalTitle", { ns: "Desktop" }),
    text: t("FailToLoadWindowModalText", { ns: "Desktop" }),
    primaryButton: {
      text: t("FailToLoadWindowTryAgainButton", { ns: "Desktop" }),
      callBack: () => {
        openShowSelectWindowModal();
      },
    },
    secondaryButton: {
      text: t("FailToLoadWindowCloseButton", { ns: "Desktop" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};
export const showErrorOnDesktopModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("ErrorOnDesktopEmoji", { ns: "Desktop" }),
    title: t("ErrorOnDesktopTitle", { ns: "Desktop" }),
    text: t("ErrorOnDesktopText", { ns: "Desktop" }),
    primaryButton: {
      text: t("ErrorOnDesktopPrimaryButton", { ns: "Desktop" }),
      callBack: () => {
        closeModal();
      },
    },
    secondaryButton: {
      text: t("ErrorOnDesktopSecondaryButton", { ns: "Desktop" }),
      callBack: () => {
        window.open(`${SLID_WEB_APP_URL}/docs?mode=chatOpen`);
        closeModal();
      },
    },
  };
  showModal(modalProps);
};
export const showStopCapturingOnDesktopModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const iframePort = await store.getState().vdocs.iframePort;

  const modalProps: ModalProps = {
    type: ModalTypes.emojiModal,
    title: t("StopCapturingOnDesktopTitle", { ns: "Desktop" }),
    text: t("StopCapturingOnDesktopText", { ns: "Desktop" }),
    primaryButton: {
      text: t("StopCapturingOnDesktopPrimaryButton", { ns: "Desktop" }),
      callBack: () => {
        sendMessageToPrimary({
          message: {
            type: "IFRAME_TO_PRIMARY_stopCapturing",
            payload: null,
          },
          port: iframePort,
        });
        closeModal();
      },
    },
    secondaryButton: {
      text: t("StopCapturingOnDesktopSecondaryButton", { ns: "Desktop" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showMobileAndTabletOnboardingModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const modalProps: ImageModalProps = {
    type: ModalTypes.imageModal,
    device: shouldShowMobileSizeModal ? "mobile" : "desktop", // mobile app's deviceType is not considered as "mobile".
    image: {
      src: "/src/design/assets/slid_welcome.png",
      altText: "Welcome",
      width: 400,
      height: 235,
    },
    title: t("MobileOnboardingModalTitle", { ns: "Modal" }),
    text: t("MobileOnboardingModalText", { ns: "Modal" }),
    primaryButton: {
      text: t("MobileOnboardingModalPrimaryButtonText", { ns: "Modal" }),
      callBack: () => {
        window.open(t("SlidGuideYoutubeLink", { ns: "common" }));
        history && history.push("/");
        closeModal();
      },
    },
    onDimClick: () => {
      history && history.push("/");
      closeModal();
    },
  };
  showModal(modalProps);
};

export const showInAppPurchaseLoadingModal = async ({ showModal, closeModal, customComponentContent }: ShowModalProps) => {
  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: false,
    customComponent: customComponentContent,
  };
  showModal(modalProps);
};

export const showPhotoLibraryPermissionRequiredModal = async ({ showModal, closeModal, reactNativeWebView }: any) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    device: "mobile",
    title: t("PhotoLibraryPermissionModalTitle", { ns: "Modal" }),
    text: t("PhotoLibraryPermissionModalBody", { ns: "Modal" }),
    primaryButton: {
      text: t("PhotoLibraryPermissionModalPrimaryButtonText", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click GO TO ALLOW in allow access modal in video note page" });
        //@ts-ignore
        reactNativeWebView.postMessage(
          JSON.stringify({
            type: "WEB_TO_APP_openPermissionSettings",
          })
        );
      },
    },
    closableDim: false,
  };
  showModal(modalProps);
};

export const showCustomModal = async ({ showModal, closeModal, closableDim, customComponentContent }: ShowModalProps) => {
  closableDim = closableDim === undefined ? true : closableDim;

  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    customComponent: customComponentContent,
    closableDim: closableDim,
  };
  showModal(modalProps);
};

export const showPlanNeededModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const isUserInVideoNote = window.location.pathname.includes("/vdocs/");
  const applicationType = await store.getState().slidGlobal.applicationType;
  const iframePort = await store.getState().vdocs.iframePort;

  trackEvent({
    eventType: eventTypes.show.PLAN_NEEDED_MODAL,
    eventProperties: {
      from: window.location.pathname,
    },
  });

  let modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("PlanNeededModalIcon", { ns: "Modal" }),
    title: t("PlanNeededModalTitle", { ns: "Modal" }),
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    closableDim: isUserInVideoNote ? false : true,
    text: t("PlanNeededModalText", { ns: "Modal" }),
    primaryButton: {
      text: t("PlanNeededModalPrimaryButton", { ns: "Modal" }),
      callBack: () => window.open("/pricing"),
    },
  };

  // Show GoToMyNote Button as secondary button for user in video note on desktop-app, otherwise just show Close Button
  if (isUserInVideoNote) {
    if (applicationType === "desktop")
      modalProps = { ...modalProps, secondaryButton: { text: t("PlanNeededModalSecondaryMyNotesButton", { ns: "Modal" }), callBack: () => redirectToMyNotes({ history, iframePort }) } };
  } else {
    modalProps = {
      ...modalProps,
      secondaryButton: {
        text: t("PlanNeededModalSecondaryCloseButton", { ns: "Modal" }),
        callBack: () => closeModal(),
      },
    };
  }

  showModal(modalProps);
};

export const showPlanUpgradeNeededModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const isUserInVideoNote = window.location.pathname.includes("/vdocs/");
  trackEvent({
    eventType: eventTypes.show.PLAN_UPGRADE_NEEDED_MODAL,
    eventProperties: {
      from: isUserInVideoNote ? "video note page" : "plain note page",
    },
  });

  const userData = store.getState().slidGlobal.userData;

  const membershiptype = userData?.payment;

  if (membershiptype === MembershipType.trial) {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      scope.setExtra("message", `Trial member should not see this plan upgrade needed modal`);
      scope.setExtra("user", userData);
      Sentry.captureMessage("Plan upgrade needed modal shown to trial member");
    });
  }

  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("PlanUpgradeNeededModalIcon", { ns: "Modal" }),
    title: t("PlanUpgradeNeededModalTitle", { ns: "Modal" }),
    text: t("PlanUpgradeNeededModalText", { ns: "Modal" }),
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    primaryButton: {
      text: t("PlanUpgradeNeededModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: eventTypes.click.UPGRADE_PLAN_IN_PLAN_UPGRADE_NEEDED_MODAL, eventProperties: { from: isUserInVideoNote ? "video note page" : "plain note page" } });
        closeModal();
        window.open("/pricing");
      },
    },
  };
  showModal(modalProps);
};

export const showActivateDayPassModal = async ({ showModal, closeModal, dispatch }: ShowModalProps) => {
  trackEvent({ eventType: eventTypes.show._24H_PASS_ACTIVATION_MODAL_IN_MY_NOTES });

  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("ActivateDayPassModalIcon", { ns: "Modal" }),
    title: t("ActivateDayPassModalTitle", { ns: "Modal" }),
    text: t("ActivateDayPassModalText", { ns: "Modal" }),
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    primaryButton: {
      text: t("ActivateDayPassModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: eventTypes.click.START_NOW_IN_24H_PASS_ACTIVATION_MODAL });
        dispatch(activateDayPass());
        closeModal();
      },
    },
    secondaryButton: {
      text: t("ActivateDayPassModalSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: eventTypes.click.LATER_IN_24H_PASS_ACTIVATION_MOAL });
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showUnauthorizedModal = async ({ showModal, closeModal, dispatch }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: "🥲",
    title: "접근 권한이 없습니다.",
    text: `현재 로그인하신 아이디는 결제 페이지에 접근할 수 없어요. 담당 엑스퍼트 또는 티쳐님께 등록 문의 부탁드립니다.`,
    closableDim: false,
    primaryButton: {
      text: "회원권 보기",
      callBack: () => {
        window.location.assign(`/pricing`);
      },
    },
    secondaryButton: {
      text: "내 노트함 가기",
      callBack: () => {
        window.location.assign(`/docs`);
      },
    },
  };
  showModal(modalProps);
};
