import { searchDocuments } from "redux/actions/vdocsActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { setSearchCategories, setSearchKeywords, setSearchResults } from "redux/actions/searchActions";
import { sortByDate, sortByName, sortByUpdated } from "components/myNotes/myNotes.utils";
import { SearchCategoryType, SortingOptionType } from "types/search";
import { useTranslation } from "react-i18next";
import { folderPathMapProps, notePathMapProps } from "types/document";

export const useSearch = () => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const { searchSortingOption } = useAppSelector((state) => state.search);
  const { documents } = useAppSelector((state) => state.vdocs);

  // Legacy search function
  const getSearchResults = async (keyword: string) => {
    const searchResult: any = await dispatch(searchDocuments(keyword));
    await dispatch(setSearchResults(!searchResult.error_message ? searchResult.documents : []));
  };

  const resetSearchKeywords = () => {
    dispatch(setSearchKeywords(""));
    dispatch(setSearchCategories({ categories: [SearchCategoryType.Images, SearchCategoryType.Folders, SearchCategoryType.Notes], selectedCategories: [] }));
  };

  const sortDocuments = (documents: any) => {
    if (searchSortingOption === SortingOptionType.Date) {
      sortByDate(documents);
    } else if (searchSortingOption === SortingOptionType.Name) {
      sortByName(documents);
    } else {
      sortByUpdated(documents);
    }
  };

  const getSortedSearchResults = (documents: any) => {
    const sortedSearchResults = [] as any;
    sortDocuments(documents);
    documents.forEach((document: any) => {
      sortedSearchResults.push(document);
    });
    return sortedSearchResults;
  };

  const getSearchFolderPathMap = () => {
    let folderPathMap: folderPathMapProps = {
      root: {
        pathText: t("MyDocs"),
        parentKeys: [],
      },
    };

    const setChildFolderPathMap = ({ folder, cumFolderPathText, cumParentKeys }: any) => {
      folder.documents.forEach((folder: any) => {
        let newCumFolderPathText = cumFolderPathText ? `${cumFolderPathText} / ${folder["title"]}` : folder["title"];
        let newCumFolderParentKeys = cumParentKeys.concat([folder["document_key"]]);

        if (folder["is_folder"]) {
          folderPathMap[folder["document_key"]] = {
            pathText: newCumFolderPathText,
            parentKeys: newCumFolderParentKeys,
          };

          setChildFolderPathMap({
            folder: folder,
            cumFolderPathText: newCumFolderPathText,
            cumParentKeys: newCumFolderParentKeys,
          });
        }
      });
    };

    setChildFolderPathMap({
      folder: {
        documents: [...documents],
      },
      cumFolderPathText: t("MyDocs"),
      cumParentKeys: ["root"],
    });

    return folderPathMap;
  };

  const getSearchNotePathMap = () => {
    const documentsPath: notePathMapProps = {
      new: t("MyDocs"),
    };
    const setChildDocumentPath = ({ folder, cumPathName }: any) => {
      folder.documents.forEach((item: any) => {
        if (item["is_folder"]) {
          let newCumPathName = cumPathName ? `${cumPathName} / ${item["title"]}` : item["title"];
          setChildDocumentPath({
            folder: item,
            cumPathName: newCumPathName,
          });
        } else {
          documentsPath[item["document_key"]] = cumPathName;
        }
      });
    };

    setChildDocumentPath({
      folder: {
        documents: documents,
      },
      cumPathName: t("MyDocs"),
    });
    return documentsPath;
  };

  return { getSearchResults, resetSearchKeywords, getSortedSearchResults, getSearchFolderPathMap, getSearchNotePathMap };
};
