export const ClickEventType = {
  //GLOBAL
  CHECK_OUT_PLANS_IN_MY_MEMBERSHIP: "Click CHECK OUT PLANS in my membership",

  //FREE TIRAL
  GO_TO_PRCICING_PAGE_IN_FREE_TRIAL_END_NOTIFICATION: "Click GO TO PRICING PAGE in Free trial ended notification",
  RECOMMENDED_NOTE_IN_PLAIN_NOTE_PAGE: "Click RECOMMENDED NOTE in Plain note page",
  GO_TO_PRICING_PAGE_IN_PLAIN_NOTE_PAGE: "Click GO TO PRICING PAGE in Plain note page",
  CHOOSE_PLAN_IN_MY_NOTES: "Click CHOOSE PLAN in My notes",
  GO_TO_PRICING_PAGE_IN_SLID_VALUE_PROPOSITION_MODAL: "Click GO TO PRICING PAGE in SLID VALUE PROPOSITION modal",
  TIME_SAVE_BANNER_IN_VIDEO_NOTE_PAGE: "Click TIME SAVE BANNER in Video note page",

  //14 DAYS STUDY CHALLENGE
  STUDY_CHALLENGE_BANNER_END_BUTTON_IN_VIDEO_NOTE_PAGE: "Click STUDY CHALLENGE BANNER END BUTTON in Video note page",
  STUDY_CHALLENGE_BANNER_DETAIL_IN_VIDEO_NOTE_PAGE: "Click STUDY CHALLENGE BANNER DETAIL in Video note page",
  CONTINUE_IN_STUDY_CHALLENGE_DAILY_MODAL: "Click CONTINUE in Study Challenge Daily Modal",
  MY_NOTES_IN_STUDY_CHALLENGE_DAILY_MODAL: "Click MY NOTES in Study Challenge Daily Modal",
  STUDY_CHALLENGE_STATUS_CARD_IN_MY_NOTES: "Click STUDY CHALLENGE STATUS CARD in My Notes",

  //24H DAY-PASS
  START_NOW_IN_24H_PASS_ACTIVATION_MODAL: "Click START NOW in 24h pass activation modal",
  LATER_IN_24H_PASS_ACTIVATION_MOAL: "Click LATER in 24h pass activation modal",
  START_24H_PASS_NOW_IN_MY_NOTES: "Click START  NOW in My notes",
  LATER_IN_PAYMENT_SUCCESS_MODAL: "Click LATER in payment success modal",
  START_NOW_IN_PAYMENT_SUCCESS_MODAL: "Click START NOW in payment success modal",

  //
  GRAB_TEXT: "Click grab text", // should keep legacy
  CHECKOUT_PLAN_IN_SUBSCRIPTION_CANCELLATION_COMPLETED_MODAL: "Click CHECKOUT PLAN in Subscription cancellation completed modal",
  CANCEL_SUBSCRIPTION_IN_SUBSCRIPTION_CANCEL_CONFIRM_MODAL: "Click CANCEL SUBSCRIPTION in Subscription cancel confirm modal",
  KEEP_SUBSCRIPTION_IN_SUBSCRIPTION_CANCEL_CONFIRM_MODAL: "Click KEEP SUBSCRIPTION in Subscription cancel confirm modal",
  CANCEL_SUBSCRIPTION_IN_MY_ACCOUNT: "Click cancel subscription button", // should keep legacy
  CLIP_RECORDING_IN_VIDEO_NOTE_PAGE: "Click CLIP RECORDING in video note page",
  UPGRADE_PLAN_IN_PLAN_UPGRADE_NEEDED_MODAL: "Click UPGRADE PLAN in PLAN UPGRADE NEEDED modal",
  SMART_LIVETEXT_IN_VIDEO_NOTE_PAGE: "Click SMART LIVETEXT in video note page",
  GO_TO_PRICING_PAGE_IN_MY_ACCOUNT: "Click GO TO PRICING PAGE in My account",

  //PRICING PAGE
  _24H_PASS_5EA_IN_PRICING_PAGE: "Click 24H PASS 5EA in pricing page",
  _24H_PASS_1EA_IN_PRICING_PAGE: "Click 24H PASS 1EA in pricing page",
  OTHER_OPTION_IN_PRICING_MODAL: "Click OTHER OPTION in pricing modal",
  PAY_BUTTON_IN_PRICING_MODAL: "Click PAY BUTTON in pricing modal",
  BUY_NOW_IN_PRICING_PAGE: "Click BUY NOW in pricing page",
  COMPARE_FEATURES_IN_DETAIL_IN_PRICING_PAGE: "Click COMPARE FEATURES IN DETAIL in pricing page",
  PREMIUM_IN_PRICING_PAGE: "Click PREMIUM in pricing page",
  STANDARD_IN_PRICING_PAGE: "Click STANDARD in pricing page",
  JOIN_CASHBACK_EVENT_IN_PRICING_PAGE: "Click JOIN CASHBACK EVENT in pricing page",
  CASHBACK_EVENT_DETAIL_IN_PRICING_PAGE: "Click CASHBACK EVENT DETAIL in pricing page",
  SLID_11_CONSULTING_IN_PRICING_PAGE: "Click SLID 1:1 CONSULTING in pricing page",
  _12MONTH_IN_PRICING_PAGE: "Click 12-MONTH in pricing page",
  _1MONTH_IN_PRICING_PAGE: "Click 1-MONTH in pricing page",
  ONE_MONTH_ONE_YEAR_IN_PRICING_PAGE: "Click 1MONTH-1YEAR in pricing page",
  _24H_PASS_IN_PRICING_PAGE: "Click 24H PASS in pricing page",

  //My notes
  SLID_GUIDE_PAGE_IN_MY_NOTES: "Click SLID GUIDE PAGE in My Notes",
  SORT_BUTTON: "Click SORT button",
  SHOW_MORE_IN_MY_NOTES: "Click SHOW MORE in My Notes",
  SEE_MORE_RESULTS: "Click SEE MORE RESULTS",
  SEARCH_FILTER_BUTTON: "Click SEARCH FILTER button",
  FOLDER_IN_SEARCH_RESULTS: "Click FOLDER in search results",
  IMAGE_IN_SEARCH_RESULTS: "Click IMAGE in search results",
  NOTE_IN_SEARCH_RESULTS: "Click NOTE in search results",

  // Install Slid
  APP_STORE_IN_SLID_DOWNLOAD_PAGE: "Click VISIT APP STORE in Slid download page",
  DESKTOP_APP_DOWNLOAD_IN_SLID_DOWNLOAD_PAGE: "Click DESKTOP APP DOWNLOAD in Slid download page",
  BROWSER_EXTENSION_DOWNLOAD_IN_SLID_DOWNLOAD_PAGE: "Click BROWSER EXTENSION DOWNLOAD in Slid download page",

  //ONBOARDING
  OPEN_NEW_VIDEO_NOTE_PAGE_IN_MY_NOTES: "Click OPEN NEW VIDEO NOTE PAGE in My Notes",
  MULTIPLE_PLATFORM_INFO_BANNER_IN_MY_NOTES: "Click MULTIPLE PLATFORM INFO BANNER in My Notes",

  // Video note
  AI_SLIDDY: "Click AI Sliddy",

  //DEMO VIDEO NOTE PAGE
  START_SLID_GUIDE_IN_DEMO_VIDEO_NOTE_PAGE: "Click START SLID GUIDE in Demo video note page",
  _1_CLICK_CAPTURE_IN_DEMO_VIDEO_NOTE_PAGE: "Click 1-CLICK CAPTURE in Demo video note page",
  GRAB_TEXT_IN_DEMO_VIDEO_NOTE_PAGE: "Click GRAB TEXT in Demo video note page",
  SLT_IN_DEMO_VIDEO_NOTE_PAGE: "Click SLT in Demo video note page",

  //WEBSITE SELECT MODAL
  START_FROM_YOUTUBE_IN_WEBSITE_SELECT_MODAL: "Click START FROM YOUTUBE in Website select modal",
  START_FROM_OTHER_WEBSITES_IN_WEBSITE_SELECT_MODAL: "Click START FROM OTHER WEBSITES in Website select modal",

  // Options in video note
  DELETE_CAPTURED_IMAGE_FROM_ALBUM: "Click DELETE CAPTURED IMAGE FROM ALBUM",
  SLID_COMMUNITY_LINK: "Click SLID COMMUNITY LINK",
  JOIN_SLID_INFO_CHATROOM: "Click JOIN SLID INFO CHATROOM in My Account Slid Community Tab",

  // SLT
  ADD_MANUALLY_IN_AUTO_TYPING_SIDEBAR: "Click ADD MANUALLY in auto-typing sidebar",
  AUTO_ADD_IN_AUTO_TYPING_SIDEBAR: "Click AUTO-ADD in auto-typing sidebar",
  AUTO_TYPING_IN_VIDEO_NOTE_PAGE: "Click AUTO-TYPING in video note page",
  AUTO_TYPING_SETTING_IN_VIDEO_NOTE_PAGE: "Click AUTO-TYPING SETTING in video note page",
  STOP_TYPING_IN_VIDEO_NOTE_PAGE: "Click STOP TYPING in video note page",

  //Video Recommendation
  RECOMMENDED_VIDEO: "Click RECOMMENDED VIDEO",
  VIDEO_RECOMMENDATION_IN_MY_NOTES: "Click VIDEO RECOMMENDATION in My notes",
  CATEGORY_IN_VIDEO_RECOMMENDATION_PAGE: "Click CATEGORY in video recommendation page",

  // image markup
  COLOR_IN_IMAGE_MARKUP_VIEW: "Click COLOR in image markup view",
  ERASER_IN_IMAGE_MARKUP_VIEW: "Click ERASER in image markup view",
  HIGHLIGHT_IN_IMAGE_MARKUP_VIEW: "Click HIGHLIGHT in image markup view",
  IMAGE_MARKUP: "Click IMAGE MARKUP",
  PEN_IN_IMAGE_MARKUP_VIEW: "Click PEN in image markup view",
  TEXT_IN_IMAGE_MARKUP_VIEW: "Click TEXT in image markup view",
  SELECT_IN_IMAGE_MARKUP_VIEW: "Click SELECT in image markup view",

  // GROUP Study Challenge
  STUDY_CHALLENGE_IN_MY_NOTES: "Click GROUP CHALLENGE in My Notes",
  SUBMIT_GROUP_CHALLENGE_NOTE: "Click SUBMIT GROUP CHALLENGE NOTE",
  GROUP_CHALLENGE_BANNER_IN_GROUP_CHALLENGE_PAGE: "Click GROUP CHALLENGE BANNER in Group challenge page",
  FOLDER_IN_GROUP_CHALLENGE_PAGE: "Click FOLDER in Group challenge page",
  NOTE_IN_GROUP_CHALLENGE_PAGE: "Click NOTE in Group challenge page",
  CANCEL_IN_GROUP_CHALLENGE_PAGE_APPLICATION_MODAL: "Click CANCEL in Group challenge application modal",
  APPLY_IN_GROUP_CHALLENGE_PAGE_APPLICATION_MODAL: "Click APPLY in Group challenge application modal",
  CANCEL_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL: "Click CANCEL in Group challenge note submit modal",
  SUBMIT_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL: "Click SUBMIT in Group challenge note submit modal",
  NOTE_TACKING_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL: "Click NOTE TAKING in Group challenge note submit modal",
  CHECK_SUBMITTED_NOTE_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL: "Click CHECK SUBMITTED NOTE in Group challenge note submit modal",
  SHOW_MORE_CHALLENGE_STATUS_IN_GROUP_CHALLENGE_PAGE: "Click SHOW MORE CHALLENGE STATUS in Group challenge page",
  APPLY_IN_GROUP_CHALLENGE_PAGE: "Click APPLY in Group challenge page",
  VIEW_CAREER_IN_GROUP_CHALLENGE_PAGE: "Click VIEW CAREER in Group challenge page",

  //Profile
  SAVE_IN_INFLOW_SURVEY_MODAL: "Click SAVE in Inflow survey modal",
};
