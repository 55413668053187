export const ShowEventType = {
  //FREE TIRAL
  SLID_VALUE_PROPOSITION_MODAL: "Show SLID VALUE PROPOSITION modal",
  FREE_TRIAL_ENDED_NOTIFICATION: "Show free trial ended notification",

  //24H DAY-PASS
  _24H_PASS_ACTIVATION_MODAL_IN_MY_NOTES: "Show 24H PASS ACTIVATION modal in My notes",
  _24H_PASS_EXPIRED_NOTIFICATION: "Show 24H PASS EXPIRED NOTIFICATION",

  //PRCIING PAGE
  PAYMENT_SUCCESS_MODAL: "Show PAYMENT SUCCESS MODAL",

  //
  PLAN_NEEDED_MODAL: "Show PLAN NEEDED modal",
  PLAN_UPGRADE_NEEDED_MODAL: "Show PLAN UPGRADE NEEDED modal",
  SUBSCRIPTION_CANCEL_FAIL_MODAL: "Show SUBSCRIPTION CANCEL FAIL MODAL",
  SEARCH_MODAL: "Show SEARCH MODAL",

  //PRICING_PAGE
  PRICING_MODAL_IN_PRICING_PAGE: "Show PRICING MODAL in pricing page",

  //ONBOARDING
  IMPROPER_URL_ERROR_IN_VIDEO_NOTE_PAGE: "Show IMPROPER URL ERROR in Video note page",

  // AI Sliddy
  AI_SLIDDY: "Show AI Sliddy",

  //DEMO PAGE
  WEBSITE_SELECT_MODAL_IN_DEMO_VIDEO_NOTE_PAGE: "Show WEBSITE SELECT MODAL in Demo video note page",

  FREE_TRIAL_BANNER_IN_MY_NOTES: "Show FREE TRIAL BANNER in My notes",

  // image markup
  CANCEL_MODAL_IN_IMAGE_MARKUP_VIEW: "Show CANCEL MODAL in image markup view",
  RESET_MODAL_IN_IMAGE_MARKUP_VIEW: "Show RESET MODAL in image markup view",

  // GROUP Study Challenge
  GROUP_CHALLENGE_APPLICATION_MODAL: "Show GROUP CHALLENGE APPLICATION MODAL",
  GROUP_CHALLENGE_NOTE_SUBMIT_MODAL: "Show GROUP CHALLENGE NOTE SUBMIT MODAL",
  CHALLENGE_STATUS_MODAL_IN_GROUP_CHALLENGE_PAGE: "Show CHALLENGE STATUS MODAL in Group challenge page",

  //Profile
  INFLOW_SURVEY_MODAL: "Show INFLOW SURVEY MODAL",
};
