import common from "./common.json";
import validation from "./validation.json";
import SignIn from "./SignIn.json";
import CardNotification from "./CardNotification.json";
import Notification from "./Notification.json";
import Modal from "./Modal.json";
import MyDocsAccountInfo from "./MyDocsAccountInfo.json";
import ExtensionInstall from "./ExtensionInstall.json";
import SlidOnboarding from "./SlidOnboarding.json";
import PlanSection from "./PlanSection.json";
import Footer from "./Footer.json";
import Header from "./Header.json";
import Features from "./Features.json";
import NewPricing from "./NewPricing.json";
import FAQ from "./FAQ.json";
import AuthRequired from "./AuthRequired.json";
import AuthRedirect from "./AuthRedirect.json";
import PricingReview from "./PricingReview.json";
import EditorComponent from "./EditorComponent.json";
import ViewNote from "./ViewNote.json";
import SweetAlertModals from "./SweetAlertModals.json";
import Landing from "./Landing.json";
import VideoNote from "./VideoNote.json";
import HowToSlidDesktop from "./HowToSlidDesktop.json";
import MyNotes from "./MyNotes.json";
import AudioRecording from "./AudioRecording.json";
import ClipRecording from "./ClipRecording.json";
import Banner from "./Banner.json";
import VideoCaptureButton from "./VideoCaptureButton.json";
import Error from "./Error.json";
import Profile from "./Profile.json";
import EditorLoadingScreen from "./EditorLoadingScreen.json";
import Desktop from "./Desktop.json";
import NoteListView from "./NoteListView.json";
import LegacyPricingPage from "./LegacyPricingPage.json";
import NewWebVideoDocument from "./NewWebVideoDocument.json";
import InstallSlid from "./InstallSlid.json";
import SlidGPT from "./SlidGPT.json";
import Demo from "./Demo.json";
import ExtensionOnboardingModal from "./ExtensionOnboardingModal.json";
import StudyChallenge from "./StudyChallenge.json";
import Search from "./Search.json";
import SmartLiveText from "./SmartLiveText.json";

export default {
  common,
  validation,
  SignIn,
  CardNotification,
  Notification,
  Modal,
  MyDocsAccountInfo,
  ExtensionInstall,
  SlidOnboarding,
  PlanSection,
  Footer,
  Header,
  Features,
  NewPricing,
  FAQ,
  AuthRequired,
  AuthRedirect,
  PricingReview,
  EditorComponent,
  ViewNote,
  SweetAlertModals,
  Landing,
  VideoNote,
  HowToSlidDesktop,
  MyNotes,
  AudioRecording,
  ClipRecording,
  Banner,
  VideoCaptureButton,
  Error,
  Profile,
  EditorLoadingScreen,
  Desktop,
  NoteListView,
  LegacyPricingPage,
  NewWebVideoDocument,
  InstallSlid,
  Demo,
  SlidGPT,
  ExtensionOnboardingModal,
  StudyChallenge,
  Search,
  SmartLiveText,
};
