import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Typography13 } from "@slid/slid-ips";
import { useAppSelector } from "hooks";
import SearchBoxHeader from "./SearchBoxHeader";
import { useTranslation } from "react-i18next";
import { useSearch } from "hooks";
import { useDocumentSearch, useFolderSearch, useImageSearch } from "hooks/queries";
import SearchNoteItem from "./resultItems/SearchNoteItem";
import SeeMoreResultsButton from "./SeeMoreResultsButton";
import SearchImageItem from "./resultItems/SearchImageItem";
import SearchFolderItem from "./resultItems/SearchFolderItem";
import { SearchCategoryType } from "types/search";
import USER_PRIVILEGES, { SlidFeatures } from "utils/privilegeManager";

const SearchBoxResultsView = () => {
  const { t } = useTranslation("Search");
  const { searchCategories, searchSortingOption, searchKeywords } = useAppSelector((state) => state.search);
  const { getSortedSearchResults } = useSearch();
  const { data: noteData } = useDocumentSearch({ keyword: searchKeywords });
  const { data: folderData } = useFolderSearch({ keyword: searchKeywords });
  const { data: imageData } = useImageSearch({ keyword: searchKeywords });
  const [sortedDocumentSearchResults, setSortedDocumentSearchResults] = useState<any[]>([]);
  const [sortedNoteSearchResults, setSortedNoteSearchResults] = useState<any[]>([]);
  const [sortedFolderSearchResults, setSortedFolderSearchResults] = useState<any[]>([]);
  const [sortedImageSearchResults, setSortedImageSearchResults] = useState<any[]>([]);
  const [visibleResultCategories, setVisibleResultCategories] = useState<SearchCategoryType[]>([]);
  const [seeMoreResultsButtonCount, setSeeMoreResultsButtonCount] = useState<{
    document: number;
    folder: number;
    note: number;
    image: number;
    all: number;
  }>({
    document: 0,
    folder: 0,
    note: 0,
    image: 0,
    all: 0,
  });
  const [visibleSection, setVisibleSection] = useState<{
    showDocumentResults: boolean;
    showFolderResults: boolean;
    showNoteResults: boolean;
    showImageResults: boolean;
  }>({
    showDocumentResults: true,
    showFolderResults: false,
    showNoteResults: false,
    showImageResults: true,
  });
  const [visibleImageResultsCount, setVisibleImageResultsCount] = useState<number>(4);
  const [visibleDocumentResultsCount, setVisibleDocumentResultsCount] = useState<number>(3);
  const isUserAbleToSearchImage = useRef<boolean>(false);
  isUserAbleToSearchImage.current = USER_PRIVILEGES.has(SlidFeatures.imageSearch);

  // if no category is selected, show all categories
  useEffect(() => {
    if (searchCategories.selectedCategories.length === 0) {
      setVisibleResultCategories([SearchCategoryType.Folders, SearchCategoryType.Notes, SearchCategoryType.Images]);
    } else {
      setVisibleResultCategories(searchCategories.selectedCategories);
    }
  }, [searchCategories]);

  // decide which result sections to show based on visibleResultCategories list && search results number
  useEffect(() => {
    setVisibleSection({
      showDocumentResults: sortedDocumentSearchResults.length > 0 && visibleResultCategories.includes(SearchCategoryType.Notes) && visibleResultCategories.includes(SearchCategoryType.Folders),
      showFolderResults: sortedFolderSearchResults.length > 0 && !visibleResultCategories.includes(SearchCategoryType.Notes) && visibleResultCategories.includes(SearchCategoryType.Folders),
      showNoteResults: sortedNoteSearchResults.length > 0 && visibleResultCategories.includes(SearchCategoryType.Notes) && !visibleResultCategories.includes(SearchCategoryType.Folders),
      showImageResults: sortedImageSearchResults.length > 0 && visibleResultCategories.includes(SearchCategoryType.Images),
    });
  }, [sortedDocumentSearchResults, sortedImageSearchResults, sortedFolderSearchResults, sortedNoteSearchResults, visibleResultCategories]);

  // set number of visible results based on visible category list
  useEffect(() => {
    switch (visibleResultCategories.length) {
      case 1:
        if (visibleResultCategories.includes(SearchCategoryType.Notes) || visibleResultCategories.includes(SearchCategoryType.Folders)) {
          setVisibleDocumentResultsCount(6);
        } else if (visibleResultCategories.includes(SearchCategoryType.Images)) {
          setVisibleImageResultsCount(16);
        }
        break;
      case 2:
        if (visibleResultCategories.includes(SearchCategoryType.Notes) && visibleResultCategories.includes(SearchCategoryType.Folders)) {
          setVisibleDocumentResultsCount(6);
        } else {
          setVisibleDocumentResultsCount(3);
          setVisibleImageResultsCount(4);
        }
        break;
      case 3:
        if (imageData && imageData.number_of_results > 0) {
          setVisibleDocumentResultsCount(3);
          setVisibleImageResultsCount(4);
        } else {
          setVisibleDocumentResultsCount(6);
        }
        break;
      default:
        break;
    }
  }, [imageData, visibleResultCategories]);

  // get search results that are sorted and sliced into certain(visibleDocumentResultsCount & visibleImageResultsCount) numbers
  useEffect(() => {
    let documentResults: any[] = [];
    const noteDataArr = noteData?.documents ?? [];
    const folderDataArr = folderData?.folders ?? [];
    const imageDataArr = imageData?.documents ?? [];
    documentResults = [...noteDataArr, ...folderDataArr];
    const sortedDocuments = getSortedSearchResults(documentResults);
    const sortedNotes = getSortedSearchResults(noteDataArr);
    const sortedFolders = getSortedSearchResults(folderDataArr);
    const sortedImages = getSortedSearchResults(imageDataArr);

    setSortedDocumentSearchResults(sortedDocuments.slice(0, visibleDocumentResultsCount));
    setSortedNoteSearchResults(sortedNotes.slice(0, visibleDocumentResultsCount));
    setSortedFolderSearchResults(sortedFolders.slice(0, visibleDocumentResultsCount));
    setSortedImageSearchResults(sortedImages.slice(0, visibleImageResultsCount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData, noteData, imageData, searchSortingOption, visibleDocumentResultsCount, visibleImageResultsCount]);

  useEffect(() => {
    const documentCount = (noteData?.number_of_results as number) + (folderData?.number_of_results as number) - visibleDocumentResultsCount;
    const folderCount = (folderData?.number_of_results as number) - visibleDocumentResultsCount;
    const noteCount = (noteData?.number_of_results as number) - visibleDocumentResultsCount;
    const imageCount = (imageData?.number_of_results as number) - visibleImageResultsCount;
    const allCount = (noteData?.number_of_results as number) + (folderData?.number_of_results as number) + (imageData?.number_of_results as number);

    setSeeMoreResultsButtonCount({
      document: documentCount > 0 ? documentCount : 0,
      folder: folderCount > 0 ? folderCount : 0,
      note: noteCount > 0 ? noteCount : 0,
      image: imageCount > 0 ? imageCount : 0,
      all: allCount,
    });
  }, [noteData, folderData, visibleDocumentResultsCount, imageData, visibleImageResultsCount]);

  return (
    <>
      <SearchBoxHeader />
      <SearchListContainer>
        {visibleSection.showDocumentResults && (
          <>
            <SectionTitle text={t("CategoryDocuments")} color={`--gray13`} />
            <DocumentListContainer>
              {sortedDocumentSearchResults.map((item: any) => {
                if (item.is_folder) {
                  return <SearchFolderItem key={item.document_key} folder={item} />;
                } else {
                  return <SearchNoteItem key={item.document_key} document={item} />;
                }
              })}
            </DocumentListContainer>
            {seeMoreResultsButtonCount.document > 0 && <SeeMoreResultsButton type={`Documents`} resultCount={seeMoreResultsButtonCount.document} />}
          </>
        )}
        {visibleSection.showFolderResults && (
          <>
            <SectionTitle text={t("CategoryFolders")} color={`--gray13`} />
            <DocumentListContainer>
              {sortedFolderSearchResults.map((item: any) => {
                return <SearchFolderItem key={item.document_key} folder={item} />;
              })}
            </DocumentListContainer>
            {seeMoreResultsButtonCount.folder > 0 && <SeeMoreResultsButton type={"Folders"} resultCount={seeMoreResultsButtonCount.folder} />}
          </>
        )}
        {visibleSection.showNoteResults && (
          <>
            <SectionTitle text={t("CategoryNotes")} color={`--gray13`} />
            <DocumentListContainer>
              {sortedNoteSearchResults.map((item: any) => {
                return <SearchNoteItem key={item.document_key} document={item} />;
              })}
            </DocumentListContainer>
            {seeMoreResultsButtonCount.note > 0 && <SeeMoreResultsButton type={"Notes"} resultCount={seeMoreResultsButtonCount.note} />}
          </>
        )}
        {isUserAbleToSearchImage.current && visibleSection.showImageResults && (
          <>
            <Divider />
            <SectionTitle text={t("CategoryImages")} color={`--gray13`} />
            <ImageListContainer hideSeeMoreButton={seeMoreResultsButtonCount.image < 1}>
              {sortedImageSearchResults.map((item: any) => {
                return <SearchImageItem key={item.thumbnail_url} document={item} />;
              })}
            </ImageListContainer>
            {seeMoreResultsButtonCount.image > 0 && <SeeMoreResultsButton type={"Images"} resultCount={seeMoreResultsButtonCount.image} />}
            <Divider />
          </>
        )}
        {isUserAbleToSearchImage.current && (
          <SeeMoreResultsButton type={"All"} resultCount={(noteData?.number_of_results as number) + (folderData?.number_of_results as number) + (imageData?.number_of_results as number)} />
        )}
      </SearchListContainer>
    </>
  );
};

const SearchListContainer = styled.div`
  margin-bottom: 4px;
`;

const DocumentListContainer = styled(SearchListContainer)``;

const SectionTitle = styled(Typography13)`
  && {
    padding: 8px 24px;
  }
`;

const ImageListContainer = styled(SearchListContainer)<{ hideSeeMoreButton: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: ${({ hideSeeMoreButton }) => (hideSeeMoreButton ? "16px" : "4px")};
  padding: 0 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray3);
  margin: 4px 0;
`;
export default SearchBoxResultsView;
