import React from "react";
import styled from "styled-components";
import { Typography13 } from "@slid/slid-ips";
import { useAppDispatch, useAppSelector } from "hooks";
import { setSearchCategories, setShowSearchModal } from "redux/actions/searchActions";
import { useTranslation } from "react-i18next";
import { SearchCategoryType } from "types/search";
import { Link } from "react-router-dom";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

interface SeeMoreResultsButtonProps {
  // "documents" indicates search results of notes and folders.
  type: "Images" | "Documents" | "All" | "Folders" | "Notes";
  resultCount: number;
}

const SeeMoreResultsButton = ({ type, resultCount }: SeeMoreResultsButtonProps) => {
  const { t } = useTranslation("Search");
  const dispatch = useAppDispatch();
  const { searchKeywords, searchCategories } = useAppSelector((state) => state.search);

  const selectedButtonTypeArr = () => {
    switch (type) {
      case "Notes":
        return [SearchCategoryType.Notes];
      case "Folders":
        return [SearchCategoryType.Folders];
      case "Images":
        return [SearchCategoryType.Images];
      case "Documents":
        return [SearchCategoryType.Notes, SearchCategoryType.Folders];
      case "All":
        return [];
    }
  };

  const handleClickButton = () => {
    trackEvent({
      eventType: eventTypes.click.SEE_MORE_RESULTS,
      eventProperties: {
        location: "search modal",
        from: "My Notes",
      },
    });
    dispatch(setShowSearchModal(false));

    dispatch(
      setSearchCategories({
        selectedCategories: selectedButtonTypeArr(),
        categories: [
          // put existing selected categories first
          ...selectedButtonTypeArr(),
          ...searchCategories.categories.filter((item: SearchCategoryType) => !selectedButtonTypeArr().includes(item)),
        ],
      })
    );
  };

  if (type !== "All") {
    return (
      <ButtonWrapper onClick={handleClickButton} to={`/docs?keywords=${searchKeywords}`}>
        <Typography13 text={t("SeeMoreResult", { type: t(`${type}`), count: isNaN(resultCount) ? 0 : resultCount })} color={`--blue8`} type={"span"} />
      </ButtonWrapper>
    );
  } else {
    return (
      <AllButtonWrapper
        to={`/docs?keywords=${searchKeywords}`}
        onClick={() => {
          dispatch(setShowSearchModal(false));
        }}
      >
        <Typography13 text={t("SeeAllResult", { count: isNaN(resultCount) ? 0 : resultCount })} color={`--blue8`} />
      </AllButtonWrapper>
    );
  }
};

const ButtonWrapper = styled(Link)`
  && {
    padding: 8px 24px;
    width: 100%;
    cursor: pointer;
    display: block;

    &:hover {
      background-color: var(--gray3);
      text-decoration: none;
    }

    &:active {
      background-color: var(--gray2);
    }
  }
`;

const AllButtonWrapper = styled(ButtonWrapper)`
  && {
    padding: 8px 24px;
    margin-bottom: 4px;
  }
`;

export default SeeMoreResultsButton;
