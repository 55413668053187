import { convertSLTBlocksToParagraph } from "components/SmartLiveText/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { setIsGettingSTTMediaPermission, setIsSTTMoreButtonClicked, setIsSTTToggledOn, setShowPermissionGuideInTextView, setShowSmartLiveTextView } from "redux/actions/sttActions";
import { setShowDisableMediaRecordingModal, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { eventTypes } from "types/eventTracking";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";

export const useSLTButton = () => {
  const { smartLiveTextLanguage, isSTTToggledOn, isManualAddingMode, isSTTActive } = useAppSelector((state) => state.sttReducer);
  const { isExtensionMediaPermitted, isRecordActive } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();
  const [SLTButtonStatus, setSLTButtonStatus] = useState<"waiting" | "recording" | "default">("default");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const [isSTTHovered, setIsSTTHovered] = useState<boolean>(false);
  const [isMouseOnSTTButton, setIsMouseOnSTTButton] = useState<boolean>(false);

  useEffect(() => {
    const updateSLTButtonUI = () => {
      if (!isSTTToggledOn) {
        setSLTButtonStatus("default");
      } else if (isSTTActive) {
        setSLTButtonStatus("recording");
      } else if (isSTTToggledOn && !isSTTActive) {
        setSLTButtonStatus("waiting");
      }
    };
    updateSLTButtonUI();
  }, [isSTTToggledOn, isSTTActive]);

  useEffect(() => {
    if (isExtensionMediaPermitted || !smartLiveTextLanguage || !isSTTToggledOn) return;
    manageExtensionPermissionStatus();
  }, [smartLiveTextLanguage, isSTTToggledOn]);

  const onMouseEnterSLTButton = () => {
    setIsSTTHovered(true);
    setIsMouseOnSTTButton(true);
  };

  const onMouseLeaveSLTButton = () => {
    setIsMouseOnSTTButton(false);
    setIsSTTHovered(false);
  };

  const onMouseEnterSLTMoreButton = () => {
    setIsSTTHovered(true);
  };

  const onMouseLeaveSLTMoreButton = () => {
    if (isMouseOnSTTButton) setIsSTTHovered(true);
  };

  const manageClipRecordingEffects = () => {
    // when video is being recorded, stop STT and show modal
    dispatch(setIsSTTToggledOn(false));
    dispatch(setShowDisableMediaRecordingModal(true));
  };

  const manageExtensionPermissionStatus = () => {
    trackEvent({
      eventType: "Check SMART LIVETEXT PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    dispatch(setShowExtensionMediaPermissionModal(true));
    dispatch(setIsGettingSTTMediaPermission(true));
    dispatch(setShowPermissionGuideInTextView(true));
  };

  const manageSmartLiveTextViewVisibility = () => {
    if (!smartLiveTextLanguage || !isExtensionMediaPermitted || isManualAddingMode) dispatch(setShowSmartLiveTextView(true));
  };

  const onToggleOnSLTButton = async () => {
    if (isRecordActive) {
      manageClipRecordingEffects();
      return;
    }
    await convertSLTBlocksToParagraph();
    manageSmartLiveTextViewVisibility();
    dispatch(setIsSTTToggledOn(true));
  };

  const onToggleOffSLTButton = () => {
    dispatch(setIsSTTToggledOn(false));
    if (!isManualAddingMode) dispatch(setShowSmartLiveTextView(false));
  };

  const onClickSLTMoreButton = (e) => {
    trackEvent({
      eventType: eventTypes.click.AUTO_TYPING_SETTING_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        location: isSTTActive ? "menu/set language" : "check all transcript",
      },
    });

    e.stopPropagation();
    dispatch(setShowSmartLiveTextView(true));
    // To keep showing SLT view when clicking this button even for auto mode users
    // isSTTMoreButtonClicked is reset as false when clicking the close button in SLT view
    dispatch(setIsSTTMoreButtonClicked(true));
  };

  const onClickSLTButton = async () => {
    trackEvent({
      eventType: isSTTToggledOn ? eventTypes.click.STOP_TYPING_IN_VIDEO_NOTE_PAGE : eventTypes.click.AUTO_TYPING_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        location: "video note main button",
      },
    });

    if (!isSTTToggledOn) {
      if (!confirmPrivilege(SlidFeatures.smartLiveText)) return showInsufficientPrivilegeModal();
      onToggleOnSLTButton();
    } else {
      onToggleOffSLTButton();
    }
  };

  return {
    onClickSLTButton,
    SLTButtonStatus,
    onClickSLTMoreButton,
    onMouseEnterSLTButton,
    onMouseLeaveSLTButton,
    onMouseEnterSLTMoreButton,
    onMouseLeaveSLTMoreButton,
    isSTTHovered,
    isMouseOnSTTButton,
  };
};
