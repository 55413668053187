import React, { useEffect, useRef, useState } from "react";
import { ModalType, useModalStore } from "store/useModalStore";
import styled from "styled-components";
import StatusBox from "./StatusBox";
import { useStudyChallengeStatus } from "hooks/queries";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

type ContainerSize = "large" | "medium" | "small" | "xsmall" | "xxsmall";

const CurrentStudyChallenge = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<ContainerSize>("large");
  const { StudyChallengeStatus } = useStudyChallengeStatus();

  useEffect(() => {
    const updateSize = () => {
      if (ref.current) {
        if (ref.current.offsetWidth >= 811) {
          setSize("large");
        } else if (ref.current.offsetWidth >= 692) {
          setSize("medium");
        } else if (ref.current.offsetWidth >= 573) {
          setSize("small");
        } else if (ref.current.offsetWidth >= 384) {
          setSize("xsmall");
        } else {
          setSize("xxsmall");
        }
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { showModal } = useModalStore();

  return (
    <CurrentStudyChallengeWrapper>
      <CurrentStudyChallengeStatusContainer ref={ref} size={size}>
        <StatusBox size={size} contentTitle={StudyChallengeStatus ? StudyChallengeStatus?.completion_rate + "%" : ""} contentDesc="내 달성률" tooltipTitle="챌린지 기간 중 달성한 날짜를 %로 계산" />
        <StatusBox
          size={size}
          headerText={StudyChallengeStatus?.submitted_today ? "오늘 노트 제출 완료!" : "오늘 노트를 제출해주세요!"}
          headerColor={StudyChallengeStatus?.submitted_today ? "--gray7" : "--red4"}
          contentTitle={StudyChallengeStatus ? `${StudyChallengeStatus?.user_submission_days}일 / ${StudyChallengeStatus?.total_days}일` : ""}
          contentDesc="내 노트 제출"
          tooltipTitle="챌린지 기간 중 달성한 횟수"
          tooltipDesc="새벽 3시를 기준으로 초기화"
        />
        <StatusBox
          size={size}
          contentTitle={StudyChallengeStatus ? StudyChallengeStatus?.group_avg_completion_rate + "%" : ""}
          contentDesc="그룹 평균 달성률"
          tooltipTitle="챌린지 멤버들의 현재 평균 달성률"
        />
        <StatusBox
          size={size}
          headerText="자세히 보기 →"
          headerColor="--blue7"
          contentTitle={StudyChallengeStatus ? `${StudyChallengeStatus?.members_submitted_today}명 / ${StudyChallengeStatus?.total_members}명` : ""}
          contentDesc="오늘 노트 제출한 멤버"
          onClickHeader={() => {
            trackEvent({
              eventType: eventTypes.click.SHOW_MORE_CHALLENGE_STATUS_IN_GROUP_CHALLENGE_PAGE,
            });

            showModal({ type: ModalType.STUDY_CHALLENGE, target: "body" });
          }}
          tooltipTitle="전체 멤버 중 오늘 노트를 제출한 멤버 수"
        />
      </CurrentStudyChallengeStatusContainer>
    </CurrentStudyChallengeWrapper>
  );
};

export default CurrentStudyChallenge;

const CurrentStudyChallengeWrapper = styled.div`
  max-width: 1040px;
  width: 100%;

  padding: 0px 10px;
`;

const CurrentStudyChallengeStatusContainer = styled.div<{ size: ContainerSize }>`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${(props) => {
    if (props.size === "xsmall") {
      return `
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      `;
    }
  }}
`;

const TextButton = styled.span`
  cursor: pointer;
`;

const StatusBoxContainer = styled.div`
  width: 100%;

  padding: 24px;

  border-radius: 8px;
  border: 1px solid var(--gray2);
  background: #fafafb;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 16px;
`;
