// auth 2.0
import React from "react";
import styles from "./VideoDocument.module.scss";
import Split from "react-split";
import VideoPlayer from "./VideoPlayer";
import VideoDocumentEditor from "./VideoDocumentEditor";
import EditorLoadingScreen from "components/editorLoadingStates/EditorLoadingScreen";
import { connect } from "react-redux";
import { fetchDocument, fetchDocuments, setDocument, setIsEditorLoading } from "redux/actions/vdocsActions";
import { sendSplitOnDragStartRequestToParentWindow, sendSplitOnDragEndRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { fetchCurrentFolder } from "components/myNotes/folder/folder.utils";
import SmartLiveTextView from "components/SmartLiveText/SmartLiveTextView";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import { fetchUser, updateUser, patchStudyChallengeData } from "redux/actions/slidGlobalActions";
import { withHooks } from "withHooks";
import { isTabletApp, isMobileApp } from "utils/utils";
import BrowserInteractionTime from "browser-interaction-time";
import { SlidFeatures } from "utils/privilegeManager";
import USER_PRIVILEGES from "utils/privilegeManager";
import { showPlanNeededModal } from "utils/modal";
import DesktopSmartLiveTextView from "../SmartLiveText/DesktopSmartLiveTextView";

export const browserInteractionTime = new BrowserInteractionTime({
  timeIntervalEllapsedCallbacks: [],
  absoluteTimeEllapsedCallbacks: [],
  browserTabInactiveCallbacks: [],
  browserTabActiveCallbacks: [],
  idleTimeoutMs: 3000,
  checkCallbacksIntervalMs: 10000,
});

class VideoDocument extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      splitGutterSize: 7,
      isSmallVideoPlayer: false,
      isBrowserInteractionIntervalCallbackSet: false,
    };
  }

  setBrowserInteractionIntervalCallback = () => {
    this.setState({ isBrowserInteractionIntervalCallbackSet: true });
    browserInteractionTime.startTimer();
    browserInteractionTime.addTimeIntervalEllapsedCallback({
      multiplier: (time) => time,
      timeInMilliseconds: 10000,
      callback: () => {
        if (!browserInteractionTime.isRunning()) return;
        if (this.props.userStudyChallengeData?.isActive) {
          // new 14-challenge data:
          this.props.patchStudyChallengeData({
            data: {
              event_name: "14_DAYS_STUDY_CHALLENGE",
              date: this.props.userStudyChallengeData.currentDate,
              study_time: 10,
            },
          });
        } else {
          // legacy 14 trial users:
          try {
            const userDataMoreInfo = JSON.parse(this.props.userData.more_info) || {};

            // legacy code:
            this.props.updateUser({
              data: {
                ...this.props.userData,
                more_info: {
                  ...userDataMoreInfo,
                  total_learning_time: userDataMoreInfo?.total_learning_time ? userDataMoreInfo?.total_learning_time + 10000 : 10000,
                },
              },
            });
          } catch {}
        }
      },
      pending: true,
    });
  };

  async componentDidMount() {
    this.props.setIsEditorLoading(true);
    const isAuthenticated = await isUserAuthenticated();
    if (isAuthenticated) {
      // fetch the user here because we no longer fetch user in app.js since auth 2.0
      if (!this.props.userData) {
        await this.props.fetchUser();
      }
    }
    if (this.props.applicationType) {
      this.setCurrentDocument();
      this.props.fetchDocuments();
    }
    if (!this.state.isBrowserInteractionIntervalCallbackSet && this.props.userData && this.props.userData.payment === "trial") {
      this.setBrowserInteractionIntervalCallback();
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.showImageMarkupPopup !== this.props.showImageMarkupPopup ||
      prevProps.showSmartLiveTextView !== this.props.showSmartLiveTextView ||
      prevProps.isImageCroppingModalOpen !== this.props.isImageCroppingModalOpen
    ) {
      const gutter = document.getElementById("gutter");
      if (gutter) {
        gutter.classList.toggle(`${styles[`dark-gutter`]}`, this.props.showImageMarkupPopup || this.props.showSmartLiveTextView || this.props.isImageCroppingModalOpen);
      }
    }

    // When opened vdocs directly, it doesn't have applicationType.
    // So wait until applicationType is set, and set the current document.
    if (prevProps.applicationType !== this.props.applicationType) {
      this.setCurrentDocument();
    }
    if (prevProps.documentKey !== this.props.documentKey) {
      await this.setCurrentDocument();
    }

    if (this.props.applicationType !== "desktop" && !isTabletApp() && this.props.isMobile && this.props.currentDocument?.mapped_videos.length === 0) {
      const plainNoteLink = window.location.pathname.replace("vdocs", "docs");
      this.props.history.push(plainNoteLink);
    }

    if (prevProps.userData === null && this.props.userData && this.props.userData.payment === "trial" && !this.state.isBrowserInteractionIntervalCallbackSet) {
      this.setBrowserInteractionIntervalCallback();
    }

    if (prevProps.editorIsLoading === true && prevProps.editorIsLoading !== this.props.editorIsLoading) {
      if (this.props.userData?.payment && !USER_PRIVILEGES.has(SlidFeatures.accessVideoDocument)) {
        if (!(this.props.notifications?.length > 0) && !this.props.modalOn) {
          return showPlanNeededModal({ showModal: this.props.showModal, closeModal: this.props.closeModal, history: this.props.history });
        }
      }
    }
  }

  async componentWillUnmount() {
    browserInteractionTime.destroy();
  }

  async setCurrentDocument() {
    const isAuthenticated = await isUserAuthenticated();
    if (isAuthenticated) {
      if (this.props.documentKey === "new") {
        if (!this.props.currentDocument?.mapped_videos) this.props.setDocument(null);
        await fetchCurrentFolder({
          documentKey: "root",
        });
        if (this.props.applicationType !== "desktop" && this.props.isMobile && !isMobileApp()) {
          this.props.history.replace(`/docs/new`);
        }
      } else {
        const documentKey = this.props.documentKey;
        if (this.props.applicationType === "extension") {
          const documentData = await this.props.fetchDocument({
            documentKey: documentKey,
            showError: false,
          });
          if (documentData.error_message) {
            this.props.history.replace(`/vdocs/new`);
          }
          await fetchCurrentFolder({
            documentKey: this.props.currentDocument?.parent_key || "root",
          });
        } else {
          await this.props.fetchDocument({
            documentKey: documentKey,
          });
          await fetchCurrentFolder({
            documentKey: this.props.currentDocument?.parent_key || "root",
          });
        }
      }
    }
  }

  handleResizingVideoPlayer(size) {
    if ((size[0] * window.innerWidth) / 100 <= 644) {
      this.setState({ isSmallVideoPlayer: true });
    } else {
      this.setState({ isSmallVideoPlayer: false });
    }
  }

  render() {
    if (isTabletApp() || (this.props.applicationType === "desktop" && !this.props.isSTTSupported)) {
      return (
        <div className={`${styles[`desktop-document-container`]}`}>
          <VideoDocumentEditor />
        </div>
      );
    }
    if (this.props.applicationType === "desktop" && this.props.isSTTSupported) {
      return (
        <div className={styles[`stt-desktop-document-container`]}>
          <div className={styles[`stt-desktop-transcript-container`]}>
            <DesktopSmartLiveTextView />
          </div>
          <div className={styles[`desktop-document-container`]}>
            <VideoDocumentEditor />
          </div>
        </div>
      );
    }
    return (
      <>
        <EditorLoadingScreen />
        <div className={`${styles[`vdocs-container`]}`}>
          {this.props.screenOrientation === "vertical" ? (
            <div className={styles[`vertical-vdocs-container`]}>
              <div className={styles[`vertical-videoplayer-container`]}>
                <VideoPlayer />
              </div>
              <div className={styles[`vertical-document-container`]}>
                <VideoDocumentEditor />
              </div>
            </div>
          ) : (
            <Split
              className={`${styles[`split-wrapper`]} d-flex`}
              sizes={this.props.editorIsDisplayed ? [60, 40] : [100, 0]}
              minSize={this.props.editorIsDisplayed ? [580, 330] : [580, 0]}
              expandToMin={false}
              gutter={(index, direction) => {
                const gutter = document.createElement("div");
                gutter.id = `gutter`;
                gutter.className = `gutter gutter-${direction} ${styles[`gutter`]}`;
                return gutter;
              }}
              gutterSize={this.state.splitGutterSize}
              gutterAlign="center"
              snapOffset={30}
              dragInterval={1}
              direction="horizontal"
              cursor="col-resize"
              onDragStart={(size) => {
                this.handleResizingVideoPlayer(size);
                sendSplitOnDragStartRequestToParentWindow();
              }}
              onDragEnd={(size) => {
                this.handleResizingVideoPlayer(size);
                sendSplitOnDragEndRequestToParentWindow();
              }}
            >
              <div className={styles[`extension-videoplayer-transcript-container`]}>
                <VideoPlayer isSmallVideoPlayer={this.state.isSmallVideoPlayer} />
                {this.props.applicationType === "extension" && this.props.isSTTSupported && <SmartLiveTextView />}
              </div>
              <VideoDocumentEditor />
            </Split>
          )}
        </div>
      </>
    );
  }
}

const actions = {
  fetchUser,
  fetchDocuments,
  fetchDocument,
  setDocument,
  setIsEditorLoading,
  updateUser,
  patchStudyChallengeData,
};

const mapStateToProps = (state) => ({
  applicationType: state.slidGlobal.applicationType,
  screenOrientation: state.slidGlobal.screenOrientation,
  editorIsDisplayed: state.vdocs.editorIsDisplayed,
  userData: state.slidGlobal.userData,
  notifications: state.slidGlobal.notifications,
  currentDocument: state.vdocs.currentDocument,
  editorIsLoading: state.vdocs.isEditorLoading,
  isSTTSupported: state.vdocs.isSTTSupported,
  userStudyChallengeData: state.slidGlobal.userStudyChallengeData,
  showImageMarkupPopup: state.vdocs.showImageMarkupPopup,
  showSmartLiveTextView: state.sttReducer.showSmartLiveTextView,
  isImageCroppingModalOpen: state.vdocs.isImageCroppingModalOpen,
});

export default connect(mapStateToProps, actions)(withHooks(VideoDocument));
