import { Typography12, Typography16, Typography20, Typography28 } from "@slid/slid-ips";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Twemoji from "react-twemoji";

const SearchBoxIntro = () => {
  const { t } = useTranslation("Search");

  return (
    <Wrapper>
      <Twemoji options={{ className: "twemoji" }}>
        <Typography28 text={t("SearchPlaceholderTitle")} color={`--gray17`} weight={700} />
      </Twemoji>
      <ContentWrapper>
        <ContentBox>
          <Typography12 text={t("PlaceholderMembershipTypeAllUser")} color={"--blue5"} weight={700} />
          <Typography20 text={t("PlaceholderSubtitle1")} color={`--blue8`} weight={700} marginTop={`16px`} />
          <Typography16 text={t("PlaceholderDescription1")} color={`--gray12`} marginTop={`8px`} />
          <ImageWrapper>
            <img src="/src/design/assets/search_modal_intro_01.png" alt="search_placeholder_1" />
          </ImageWrapper>
        </ContentBox>
        <ContentBox>
          <Typography12 text={t("PlaceholderMembershipTypeMembership")} color={"--blue5"} weight={700} />
          <Typography20 text={t("PlaceholderSubtitle2")} color={`--blue8`} weight={700} marginTop={`16px`} />
          <Typography16 text={t("PlaceholderDescription2")} color={`--gray12`} marginTop={`8px`} />
          <ImageWrapper>
            <img src="/src/design/assets/search_modal_intro_02.png" alt="search_placeholder_2" />
          </ImageWrapper>
        </ContentBox>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  gap: 32px;

  width: 100%;
  height: 100%;

  background: linear-gradient(145.59deg, #cae9ff 8.67%, #cad4fd 113.09%);

  .twemoji {
    width: 28px;
    height: 28px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 40px 44px;
  background: var(--white);
  box-shadow: var(--boxShadow3);
  border-radius: 12px;
  flex: 1;
`;

const ImageWrapper = styled.div`
  width: auto;
  height: 120px;
  margin: 0 auto;
  margin-top: 32px;

  img {
    height: 100%;
  }
`;

export default SearchBoxIntro;
