// auth 2.0
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./fonts.css";
import "@slid/slid-ips/styles/colors.css";
import "@slid/slid-ips/styles/effects.css";
import "@slid/slid-ips/styles/globals.css";
import "@slid/slid-ips/styles/tooltip.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import env from "config/env";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import store from "./redux/store";
import { initSentry } from "utils/sentry";
import { CustomCognitoStorage } from "utils/auth/cognitoAuthToolkit";
import { createInstance, HackleProvider } from "@hackler/react-sdk";
import { polyfill } from "mobile-drag-drop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";

initSentry();

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
const AMPLIFY_REGION = env.common_api_key.amplify.region;
const USER_POOL_ID = env.common_api_key.amplify.user_pool_id;
const USER_POOL_WEB_CLIENT_ID = env.common_api_key.amplify.user_pool_web_client_id[env.currentEnv];
const SLID_WEB_SITE_HOST = env.end_point_url.slid_web_site_host;
const SLID_OAUTH_HOST = env.end_point_url.slid_oauth_host;
const HACKLE_CLIENT = createInstance(env.api_key.hackle_key);
const queryClient = new QueryClient();

//Set extension version in local storage
const urlParams = new URLSearchParams(window.location.search);
const extensionVersion = urlParams.get("slid_extension_version");
if (extensionVersion) {
  localStorage.setItem("extensionVersion", extensionVersion);
}
// Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure({
  Auth: {
    region: AMPLIFY_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    storage: new CustomCognitoStorage(),
    cookieStorage: {
      domain: `${SLID_WEB_SITE_HOST}`,
      path: "/",
      secure: env.currentEnv !== "development", // safari localhost cookie issue
    },
    // redirectSignIn & redirectSignOut must be inside the AWS Cognito App client setting
    oauth: {
      domain: SLID_OAUTH_HOST,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${SLID_WEB_APP_URL}/oauth_redirect`,
      redirectSignOut: `${SLID_WEB_APP_URL}/docs`,
      responseType: "code",
    },
  },
});

// To enable drag and drop on touch devices
polyfill();
const onTouchMoveDummyFunction = () => {};
window.removeEventListener("touchmove", onTouchMoveDummyFunction, { passive: false });
window.addEventListener("touchmove", onTouchMoveDummyFunction, { passive: false });

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Route
          path={`/`}
          render={(props) => {
            return (
              <HackleProvider hackleClient={HACKLE_CLIENT}>
                <App {...props} />
              </HackleProvider>
            );
          }}
        />
      </Router>
    </QueryClientProvider>
  </Provider>
);
