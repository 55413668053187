import { SlidNoteDocument, SlidFolderDocument } from "./document";

export enum SearchCategoryType {
  Images = "Images",
  Notes = "Notes",
  Folders = "Folders",
}

export enum SortingOptionType {
  Date = "Date",
  Name = "Name",
  Updated = "Updated",
}

export interface DocumentSearchResponse {
  data: {
    number_of_results: number;
    documents: SlidNoteDocument[];
  };
}

export interface FolderSearchResponse {
  data: {
    number_of_results: number;
    folders: SlidFolderDocument[];
  };
}

export interface RecentDocumentResponse {
  data: {
    number_of_results: number;
    recent_documents: SlidFolderDocument[];
  };
}
