import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import SearchBoxSearchInput from "./SearchBoxSearchInput";
import SearchBoxIntro from "./placeholders/SearchBoxIntro";
import SearchBoxPlaceholder from "./placeholders/SearchBoxPlaceholder";
import SearchBoxResultsView from "./SearchBoxResultsView";
import SearchBoxNoResultPlaceholder from "./placeholders/SearchBoxNoResultPlaceholder";
import { useDocumentSearch, useFolderSearch, useImageSearch } from "hooks/queries";
import LoadMoreContainer from "components/loaders/LoadingComponent";

const SearchBox = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);
  const { searchKeywords } = useAppSelector((state) => state.search);
  const { data: noteData, isLoading: isLoadingNotes } = useDocumentSearch({ keyword: searchKeywords });
  const { data: folderData, isLoading: isLoadingFolders } = useFolderSearch({ keyword: searchKeywords });
  const { data: imageData, isLoading: isLoadingImages } = useImageSearch({ keyword: searchKeywords });

  const [isNewToSearching, setIsNewToSearching] = useState<boolean>();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    // show loading component if all 3 queries are loading
    if (isLoadingNotes && isLoadingFolders && isLoadingImages) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isLoadingNotes, isLoadingFolders, isLoadingImages]);

  useEffect(() => {
    /**
     * Check if user is new or not
     * hasSearched is a flag to indicate if user has searched before.
     * If user has searched before, this flag is updated in the method called handleSearchInput in useSearch custom hook.
     */

    const more_info = userData?.more_info ? JSON.parse(userData.more_info) : {};
    const hasSearched = more_info.has_searched;
    if (hasSearched) {
      setIsNewToSearching(false);
    } else {
      setIsNewToSearching(true);
    }
  }, [userData]);

  const renderSearchBoxContent = () => {
    /** Render different content based on the following conditions:
     * 1. User is new and there is no search keyword: search box intro
     * 2. User is not new and there is no search keyword: default placeholder
     * 3. There is search keyword: search result list
     */
    if (isNewToSearching && searchKeywords === "") {
      return <SearchBoxIntro />;
    } else if (!isNewToSearching && searchKeywords === "") {
      return <SearchBoxPlaceholder />;
    } else if (showLoading) {
      return <LoadMoreContainer />;
    } else if ((noteData && noteData?.number_of_results > 0) || (folderData && folderData?.number_of_results > 0) || (imageData && imageData?.number_of_results > 0)) {
      return <SearchBoxResultsView />;
    } else {
      return <SearchBoxNoResultPlaceholder />;
    }
  };

  return (
    <Wrapper>
      <SearchBoxSearchInput />
      <SearchContentWrapper>{renderSearchBoxContent()}</SearchContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 880px;

  background: var(--white);
  overflow: hidden;
  border-radius: 12px;
`;

const SearchContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default SearchBox;
