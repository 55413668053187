import store from "redux/store";

export const convertSLTBlocksToParagraph = async () => {
  const editorInstance = store.getState().vdocs.editorInstance;

  const noteData = await editorInstance.save();
  await noteData.blocks.forEach(async (noteBlock: any, index: number) => {
    if (noteBlock.type === "smartLiveText") {
      editorInstance.blocks.delete(index);
      if (!noteBlock.data) return;
      editorInstance.blocks.insert("paragraph", { text: noteBlock.data.text }, {}, index, true);
    }
  });
};

export const SUPPORTED_LANGUAGES = [
  {
    text: "English",
    subText: "",
  },
  {
    text: "한국어",
    subText: "(Korean)",
  },
  {
    text: "中文",
    subText: "(Simplified Chinese)",
  },
  {
    text: "हिन्दी",
    subText: "(Hindi)",
  },
  {
    text: "Español",
    subText: "(Spanish)",
  },
  {
    text: "日本語",
    subText: "(Japanese)",
  },
  {
    text: "Français",
    subText: "(French)",
  },
  {
    text: "Português",
    subText: "(Portuguese)",
  },
  {
    text: "Deutsch",
    subText: "(German)",
  },
  {
    text: "Dutch",
    subText: "(Dutch)",
  },
];
