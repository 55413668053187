import React, { useEffect } from "react";
import styled from "styled-components";
import BaseModalContainer from "./BaseModalContainer";
import ModalPortal from "./ModalPortal";
import { BasicModalProps } from "types/modal";
import { useModalStore } from "store/useModalStore";
import { Icon, Tooltip, Typography14, Typography15, Typography28 } from "@slid/slid-ips";
import { useStudyChallengeParticipants } from "hooks/queries/useStudyChallenge";
import LoadingScreen from "components/loaders/LoadingScreen";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const GroupStudyChallengeModal = ({ closable, target = "body" }: BasicModalProps) => {
  const { hideModal } = useModalStore();
  const { StudyChallengeParticipants, isLoading } = useStudyChallengeParticipants();

  useEffect(() => {
    trackEvent({
      eventType: eventTypes.show.CHALLENGE_STATUS_MODAL_IN_GROUP_CHALLENGE_PAGE,
    });
  }, []);

  if (isLoading) return <LoadingScreen />;

  let submissionKeys = StudyChallengeParticipants!.length > 0 ? Object.keys(StudyChallengeParticipants![0].weekly_submission) : [];
  submissionKeys = [...submissionKeys, ...Array(Math.max(7 - submissionKeys.length, 0)).fill("")];

  return (
    <ModalPortal target={target}>
      <BaseModalContainer
        callback={() => {
          hideModal();
        }}
        closable={closable}
      >
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <ModalHeaderContainer>
            <CloseButtonContainer>
              <Icon icon={`x24`} color={`--gray15`} onClick={() => hideModal()} />
            </CloseButtonContainer>
            <ModalHeader>
              <Typography28 weight={700} color={`--gray19`} text={`챌린지 달성 현황`} type={`span`} />
              <Typography15 weight={500} color={`--blue7`} text={`최근 7일 이내 제출 결과만 확인이 가능해요!`} type={`span`} />
            </ModalHeader>
          </ModalHeaderContainer>
          <TableContainer>
            <StyledTable>
              <StyledThead>
                <tr>
                  <StyledTh>
                    <Typography14 weight={500} color="--gray10" text="이름" />
                  </StyledTh>
                  <StyledTh>
                    <Typography14 weight={500} color="--gray10" text="달성률" />
                  </StyledTh>
                  {submissionKeys.map((key, index) => (
                    <StyledTh key={index}>
                      <Typography14 weight={500} color="--gray10" text={index === 0 ? "오늘" : key && index === 1 ? "어제" : key} />
                    </StyledTh>
                  ))}
                </tr>
              </StyledThead>
              <StyledTbody>
                {StudyChallengeParticipants?.map((participant, index) => (
                  <StyledTr key={index}>
                    <StyledTd className="name">
                      <Tooltip placement="bottom" title={participant.username}>
                        <Typography15 weight={500} color="--gray14" text={participant.username} />
                      </Tooltip>
                    </StyledTd>
                    <StyledTd>
                      <Typography15 weight={500} color="--gray14" text={`${participant.completion_rate.toString()}%`} />
                    </StyledTd>
                    {submissionKeys.map((key) => (
                      <StyledTd key={key}>
                        <Typography15
                          weight={500}
                          color={participant.weekly_submission[key] ? "--gray14" : "--gray6"}
                          text={key ? (participant.weekly_submission[key] ? "제출 완료" : "미제출") : ""}
                        />
                      </StyledTd>
                    ))}
                  </StyledTr>
                ))}
              </StyledTbody>
            </StyledTable>
          </TableContainer>
        </ModalContent>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  z-index: 9999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;

  max-height: 696px;
  overflow: auto;

  display: flex;
  flex-direction: column;

  padding: 60px;
`;

const ModalHeaderContainer = styled.div`
  background-color: white;
  margin-bottom: 20px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;

  th p {
    width: 63.5px;
    height: 20px;
  }

  td p {
    width: 63.5px;
    height: 23px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledThead = styled.thead`
  background-color: #f2f2f2;

  tr th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
  }
`;

const StyledTh = styled.th`
  padding: 8px 20px;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const StyledTbody = styled.tbody``;

const StyledTr = styled.tr`
  &:first-child {
    background-color: var(--orange1_light);
  }

  &:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const StyledTd = styled.td`
  padding: 16px 20px;

  &.name p {
    width: 82px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
`;

export default GroupStudyChallengeModal;
