import React from "react";
import { Typography13 } from "@slid/slid-ips";
import styled from "styled-components";
import { SlidNoteDocument } from "types/document";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { setShowSearchModal } from "redux/actions/searchActions";
import { isTabletApp } from "utils/utils";
import { useTranslation } from "react-i18next";

interface SearchImageItemProps {
  document: SlidNoteDocument;
}

const SearchImageItem = ({ document }: SearchImageItemProps) => {
  const dispatch = useAppDispatch();
  const { isTabletAppOpenedInSplitView } = useAppSelector((state) => state.slidGlobal);
  const { t } = useTranslation("MyNotes");

  return (
    <Wrapper
      to={isTabletApp() && isTabletAppOpenedInSplitView ? `/vdocs/${document.document_key}` : `/docs/${document.document_key}`}
      onClick={() => {
        trackEvent({
          eventType: eventTypes.click.IMAGE_IN_SEARCH_RESULTS,
          eventProperties: {
            location: "search modal",
            from: "My Notes",
          },
        });
        dispatch(setShowSearchModal(false));
      }}
    >
      <ImageWrapper>
        <ThumbnailImage src={document.thumbnail_url} alt={document.title} />
      </ImageWrapper>
      <NoteTitleWrapper>
        <Typography13 text={document.title ? document.title : t("UntitledNote")} color={`--gray17`} />
      </NoteTitleWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  width: 200px;
  text-decoration: none;

  &:hover {
    border: 1px solid var(--blue8);
    text-decoration: none;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 110px;
  background: var(--gray5);
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NoteTitleWrapper = styled.div`
  padding: 12px 16px;
`;

export default SearchImageItem;
