import { ModalTypes, Typography15, useModal } from "@slid/slid-ips";
import env from "config/env";
import { useAppSelector } from "hooks";
import { useStudyChallengeNote, useStudyChallengeStatus, useUserStudyChallenge } from "hooks/queries";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";
import { sendMessageToPrimary } from "utils/utils";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

const VideoNoteStudyChallengeInfo = () => {
  const { isUserParticipated, userStudyGoal } = useUserStudyChallenge();
  const { isNoteSubmitted, submitStudyChallengeNote, reSubmitStudyChallengeNote } = useStudyChallengeNote();
  const { StudyChallengeStatus } = useStudyChallengeStatus();

  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { iframePort } = useAppSelector((state) => state.vdocs);

  const { showModal, closeModal } = useModal();

  const [isHover, setIsHover] = React.useState(false);

  const history = useHistory();

  if (!isUserParticipated) return <></>;

  return (
    <Wrapper>
      <VideoNoteStudyChallengeInfoWrapper
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <LeftContainer>
          <DayInfo>
            <Typography15 weight={700} color="--blue8" text={`${StudyChallengeStatus?.today}/${StudyChallengeStatus?.total_days}일차`} type={"span"} />
          </DayInfo>
          <Typography15 weight={500} color="--gray15" text={userStudyGoal} type={"span"} />
        </LeftContainer>
        <RightContainer>
          <Typography15
            weight={700}
            color="--gray15"
            text={"노트 제출하기"}
            type={"span"}
            style={
              isHover
                ? {
                    textDecoration: "underline",
                  }
                : {}
            }
            onClick={async () => {
              trackEvent({
                eventType: eventTypes.click.SUBMIT_GROUP_CHALLENGE_NOTE,
                eventProperties: {
                  location: "Video note page",
                },
              });

              if (isNoteSubmitted) {
                trackEvent({
                  eventType: eventTypes.show.GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                  eventProperties: {
                    status: "failed",
                    from: "Video note page",
                  },
                });

                showModal({
                  type: ModalTypes.emojiModal,
                  device: "desktop",
                  title: "이미 제출한 노트에요!",
                  emoji: "🤔",
                  text: "오늘 제출한 노트와 같은 노트에요. 기존 노트 대신 이 노트로 다시 제출하시겠어요?",
                  primaryButton: {
                    callBack: async () => {
                      trackEvent({
                        eventType: eventTypes.click.SUBMIT_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                      });

                      await reSubmitStudyChallengeNote();

                      if (applicationType === "extension") {
                        window.open("/docs?section=study-challenge");
                      } else {
                        if (applicationType === "desktop") {
                          sendMessageToPrimary({
                            message: {
                              type: "IFRAME_TO_PRIMARY_OPEN_URL",
                              payload: `${SLID_WEB_APP_URL}/docs?section=study-challenge`,
                            },
                            port: iframePort,
                          });

                          return;
                        }

                        history.push("/docs?section=study-challenge");
                      }
                    },
                    text: "다시 제출하기",
                  },
                  secondaryButton: {
                    callBack: () => {
                      trackEvent({
                        eventType: eventTypes.click.CANCEL_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                      });

                      closeModal();
                    },
                    text: "취소",
                  },
                });
              } else {
                await submitStudyChallengeNote();

                trackEvent({
                  eventType: eventTypes.show.GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                  eventProperties: {
                    status: "success",
                    from: "Video note page",
                  },
                });

                showModal({
                  type: ModalTypes.emojiModal,
                  device: "desktop",
                  title: "노트 제출 완료!",
                  emoji: "🥳",
                  text: `${StudyChallengeStatus?.today}/${StudyChallengeStatus?.total_days}일차 노트가 제출 완료되었어요.`,
                  primaryButton: {
                    callBack: () => {
                      trackEvent({
                        eventType: eventTypes.click.CHECK_SUBMITTED_NOTE_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                      });

                      if (applicationType === "extension") {
                        window.open("/docs?section=study-challenge");
                      } else {
                        if (applicationType === "desktop") {
                          sendMessageToPrimary({
                            message: {
                              type: "IFRAME_TO_PRIMARY_OPEN_URL",
                              payload: `${SLID_WEB_APP_URL}/docs?section=study-challenge`,
                            },
                            port: iframePort,
                          });
                          return;
                        }

                        history.push("/docs?section=study-challenge");
                      }
                    },
                    text: "제출된 노트 확인",
                  },
                  secondaryButton: {
                    callBack: () => {
                      trackEvent({
                        eventType: eventTypes.click.NOTE_TACKING_IN_GROUP_CHALLENGE_NOTE_SUBMIT_MODAL,
                      });

                      closeModal();
                    },
                    text: "노트 이어쓰기",
                  },
                });
              }
            }}
          />
        </RightContainer>
      </VideoNoteStudyChallengeInfoWrapper>
    </Wrapper>
  );
};

export default VideoNoteStudyChallengeInfo;

const Wrapper = styled.div`
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 18px;
`;

const VideoNoteStudyChallengeInfoWrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 7px 16px;
  border-radius: 4px;
  background: var(--gray2);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightContainer = styled.div`
  cursor: pointer;
`;

const DayInfo = styled.div`
  padding: 4px 12px;
  border-radius: 100px;
  border: 1px solid var(--blue5);
  background: var(--white);
`;
