import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Typography15, Typography13, Typography11, ColorTypes, Link, Sticker, Typography24, useModal } from "@slid/slid-ips";
import { useAppDispatch } from "hooks";
import { setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { sendUserIsGettingExtensionMediaPermission } from "utils/extensionInterface/sendToExtension";
import { trackEvent } from "utils/eventTracking";

const ExtensionMediaPermissionModal = () => {
  const { t } = useTranslation("VideoNote");
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  useEffect(() => {
    // When user sees this modal, add this item to session storage not to show extension popup for permission.
    sendUserIsGettingExtensionMediaPermission(true);
    return () => {
      sendUserIsGettingExtensionMediaPermission(false);
    };
  }, []);

  return (
    <ModalWrapper
      onClick={() => {
        trackEvent({ eventType: "Click SMART LIVETEXT PERMISSION DIM in video note page" });
        dispatch(setShowExtensionMediaPermissionModal(false));
        closeModal();
      }}
    >
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tip></Tip>
        <Typography24
          // @ts-ignore
          text={<Trans components={{ blue: <Typography24 text={t("BlueText")} color={`--blue8`} style={{ display: "inline" }} /> }}>{t("ExtensionPermissionModalTitle")}</Trans>}
          color={`--white`}
        />
        <Typography13 text={t("ExtensionPermissionModalDesc")} color={`--gray9`} marginTop={`12px`} style={{ margin: "12px 0 0 0" }} />
        <GuideStepWrapper>
          <GuideTextGroupWrapper>
            <NumberWrapper>
              <Typography11 text="1" color="--white" weight={700} />
            </NumberWrapper>
            <GuideTextWrapper>
              <Typography15 text={t("ExtensionPermissionGuide1_1")} color={`--white`} />
              <IconImageWrapper>
                <IconImage src={"/src/icons/icon_puzzle.png"} />
              </IconImageWrapper>
              <Typography15 text={t("ExtensionPermissionGuide1_2")} color={`--white`} />
            </GuideTextWrapper>
          </GuideTextGroupWrapper>
          <GuideImage src={t("ModalImage1")} />
        </GuideStepWrapper>
        <GuideStepWrapper>
          <GuideTextGroupWrapper>
            <NumberWrapper>
              <Typography11 text="2" color="--white" weight={700} />
            </NumberWrapper>
            <GuideTextWrapper>
              <Typography15 text={t("ExtensionPermissionGuide2_1")} color={`--white`} />
              <IconImageWrapper>
                <IconImage src={"/src/logo/slid_logo_128.png"} />
              </IconImageWrapper>
              <Typography15 text={t("ExtensionPermissionGuide2_2")} color={`--white`} />
            </GuideTextWrapper>
          </GuideTextGroupWrapper>
          <GuideImage src={t("ModalImage2")} />
        </GuideStepWrapper>
        <TipWrapper>
          <Sticker color={ColorTypes.Green} text={`Tip`} />
          <Typography13
            // @ts-ignore
            text={<Trans>{t("ExtensionPermissionModalTip")}</Trans>}
            color={`--gray9`}
          />
        </TipWrapper>
        <Link infoText={t("NeedHelp")} color={`--blue4`} linkText={t("NeedHelpLinkText")} href={t("PermissionLink")} newTab={true} style={{ margin: "36px auto 0" }} />
      </ModalContainer>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: -50vh;
  right: 0;
  left: -50vw;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 37, 41, 0.4);
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  right: 80px;

  padding: 24px 20px;
  background: var(--gray17);
  border-radius: 8px;
  width: fit-content;
`;

const Tip = styled.div`
  position: absolute;
  top: -6px;
  right: 60px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 0 0 4px 0;
  background-color: var(--gray17);
`;

const GuideStepWrapper = styled.div`
  margin-top: 32px;
`;

const GuideTextGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  width: 22px;
  height: 22px;

  background: var(--blue8);
  border-radius: 8px;
`;

const GuideTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const IconImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;

const GuideImage = styled.img`
  width: fit-content;
  height: fit-content;
  max-width: 290px;
  margin-top: 14px;
`;

const TipWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 14px;
`;

export default ExtensionMediaPermissionModal;
