import React, { memo } from "react";
import styled from "styled-components";
import { Typography17, Button, Typography24 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { setShowDisableMediaRecordingModal } from "redux/actions/vdocsActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { setIsSTTToggledOn, setStopSTT } from "redux/actions/sttActions";

interface DisableMediaRecordingModalProps {
  clipRecordStart: () => void;
  clipRecordStop: () => void;
}
const DisableMediaRecordingModal = memo(({ clipRecordStart, clipRecordStop }: DisableMediaRecordingModalProps) => {
  const { t } = useTranslation("Modal");
  const dispatch = useAppDispatch();
  const { isSTTToggledOn, isSTTActive } = useAppSelector((state) => state.sttReducer);

  return (
    <ModalContainer>
      <Typography24 text={t(isSTTToggledOn ? "DisableSTTModalTitle" : "DisableVideoSnippetModalTitle")} color={`--gray17`} weight={700} />
      <Typography17 text={t(isSTTToggledOn ? "DisableSTTModalText" : "DisableVideoSnippetModalText")} color={`--gray15`} weight={400} marginTop={`16px`} />
      <ButtonWrapper>
        <Button
          text={t("DisableRecordingMediaModalSecondaryButtonText")}
          appearance={`secondary`}
          callback={() => {
            dispatch(setShowDisableMediaRecordingModal(false));
          }}
          size={`medium`}
        />
        <Button
          text={t(isSTTToggledOn ? "UseVideosnippet" : "StartSmartLiveText")}
          appearance={`primary`}
          callback={() => {
            dispatch(setShowDisableMediaRecordingModal(false));
            if (isSTTToggledOn) {
              dispatch(setIsSTTToggledOn(false));
              if (isSTTActive) dispatch(setStopSTT());
              clipRecordStart();
            } else {
              clipRecordStop();
              dispatch(setIsSTTToggledOn(true));
            }
          }}
          size={`medium`}
        />
      </ButtonWrapper>
    </ModalContainer>
  );
});

const ModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 36px;

  background-color: #fff;
  box-shadow: var(--boxShadow3);
  border-radius: 8px;
  min-width: 40rem;
  width: 42rem;
  z-index: 99999;

  @media screen and (max-width: 799px) {
    width: 85vw;
    min-width: initial;
    max-width: 400px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 36px;
`;

export default DisableMediaRecordingModal;
